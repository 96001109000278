import type { Article } from "../../../client";

import { createTrackedArticle } from "../articleTracking";

export function trackAddBookmarkEvent(
  pulseSdk: any,
  {
    NEXT_PUBLIC_PULSE_CLIENT_ID,
    NEXT_PUBLIC_DOMAIN,
    NEXT_PUBLIC_PREMIUM_NEWSROOM,
    article,
    route,
    loggedIn,
    user_id
  }: {
    NEXT_PUBLIC_PULSE_CLIENT_ID: string;
    NEXT_PUBLIC_DOMAIN: string;
    NEXT_PUBLIC_PREMIUM_NEWSROOM: boolean;
    article: Article;
    route: string;
    loggedIn: boolean;
    user_id: string;
  }
) {
  pulseSdk?.track("trackerEvent", {
    type: "Engagement",
    schema: "http://schema.schibsted.com/events/engagement-event.json/254.json",
    action: "Click",
    intent: "Save",
    object: createTrackedArticle(
      article,
      route,
      NEXT_PUBLIC_DOMAIN,
      NEXT_PUBLIC_PREMIUM_NEWSROOM,
      loggedIn,
      user_id
    )
  });
}
