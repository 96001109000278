import type { Article } from "../../../client";

export function trackShareEvent(
  pulseSdk: any,
  {
    NEXT_PUBLIC_PULSE_CLIENT_ID,
    article,
    target
  }: { NEXT_PUBLIC_PULSE_CLIENT_ID: string; article: Article; target: string }
) {
  const share_target = target + "_share";

  pulseSdk?.track("trackerEvent", {
    type: "Engagement",
    schema: "http://schema.schibsted.com/events/engagement-event.json/194.json",
    action: "Click",
    object: {
      "@id": `sdrn:${NEXT_PUBLIC_PULSE_CLIENT_ID}:article:${article.article_id}:element:${share_target}`,
      type: "UIElement",
      elementType: "share_button",
      "spt:custom": {
        article_id: article.article_id,
        article_title: article.title.value,
        sponsor: article.sponsor || null,
        is_sponsored: !!article.sponsor,
        share_target
      }
    }
  });
}
