import React, { useContext, useState } from "react";
import Image from "next/image";

import type { Article, Url } from "../../../../client";
import type { ResourceComponent } from "../resourceToComponents";

import { isUrl } from "../../../../client";
import { ArticleContext } from "../../../../contexts/ArticleContext";
import { TrackingContext } from "../../../../contexts/TrackingContext";
import { Caption } from "../common/Caption";
import {
  getFormatPostfix,
  getGroupedStyle,
  getHorizontalPaddingClass
} from "../../helpers";
import { getArticleDisplaySize } from "../helpers/article-size";
import { getImageSizes } from "../../../../helpers";

const sizes = getImageSizes({ web: "668px", tablet: "100vw" });

const getVideoId = (video: Url) => {
  return video!.data!.custom_properties!.video_id;
};

const isVideoAMainResource = (article: Article, resource: Url) => {
  const { main_resource } = article;
  if (!main_resource) return false;

  if (!isUrl(main_resource)) {
    return false;
  }

  if (main_resource.data?.source?.toLowerCase() === "youtube") {
    return getVideoId(main_resource) === getVideoId(resource);
  }

  return false;
};

export const getThumbnailUrl = (url: Url, displaySize: string) => {
  const videoId = getVideoId(url);
  let thumbSize = "mqdefault";

  if (displaySize === "large") {
    thumbSize = "hqdefault";
  }

  return `https://img.youtube.com/vi/${videoId}/${thumbSize}.jpg`;
};

export const VideoResource: ResourceComponent<Url> = ({
  resource: url,
  isOpen,
  group
}) => {
  const { article } = useContext(ArticleContext);
  const { playVideo } = useContext(TrackingContext);
  const videoId = getVideoId(url);
  const [played, setPlayed] = useState(false);

  const isThisVideoTheMain = isVideoAMainResource(article, url);
  const displaySize =
    isThisVideoTheMain && !isOpen ? getArticleDisplaySize(article) : "large";

  // todo: get origin url
  const originUrl = "";

  const thumbnailUrl = url.data?.image || getThumbnailUrl(url, displaySize);

  const play = (displaySize: string) => (e: React.SyntheticEvent) => {
    if (displaySize === "small" || displaySize === "tiny") {
      return;
    }
    e.stopPropagation();
    setPlayed(true);
    if (videoId) {
      playVideo(videoId, article);
    }
  };

  const formatPostfix = getFormatPostfix(article.format);

  if (!played) {
    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <div
        className={`resource resource--image resource--image--video resource--image--video${formatPostfix} resource--image--${displaySize}
        ${getHorizontalPaddingClass(url.horizontal_padding)}
        ${getGroupedStyle(group)}`}
      >
        <div>
          <Image
            src={thumbnailUrl}
            alt=""
            className="resource-videoimg"
            onClick={play(displaySize)}
            onKeyDown={play(displaySize)}
            sizes={sizes}
            width={0}
            height={0}
          />
          <span
            className="resource-playbtn"
            onClick={play(displaySize)}
            onKeyDown={play(displaySize)}
          >
            <i className="icon icon--play" />
          </span>
        </div>
        <Caption text={url.title?.value} show={url.show_caption} />
      </div>
    );
  }

  return (
    <div
      className={`resource resource--video resource--video--${displaySize}
      ${getHorizontalPaddingClass(url.horizontal_padding)}
      ${getGroupedStyle(group)}`}
    >
      <iframe
        className={`resource-video resource--image--video resource--image--video${formatPostfix}`}
        data-video-id={videoId}
        frameBorder="0"
        allow="autoplay"
        allowFullScreen
        title={videoId}
        src={`https://www.youtube.com/embed/${videoId}?rel=0&amp;autoplay=1&enablejsapi=1&amp;origin=${originUrl}`}
      />
      <Caption text={url.title?.value} show={url.show_caption} />
    </div>
  );
};
