import _ from "lodash";

import type { Topic } from "../../../client";
import type { BoxHeading } from "../../../client/ContentApi/models/boxheading";

import { getId, getType, getUrl, mapPathnameToView } from "./trackViewEvent";

const getObject = (
  NEXT_PUBLIC_PULSE_CLIENT_ID: string,
  NEXT_PUBLIC_DOMAIN: string,
  pathname: string,
  asPath: string,
  resource: BoxHeading,
  position: number,
  parsedId: string,
  topic?: Topic
) => {
  const view = mapPathnameToView(pathname);
  const type = getType(view);
  return {
    name: resource.title,
    "@id": `sdrn:${NEXT_PUBLIC_PULSE_CLIENT_ID}:frontpage:card:themebox:${parsedId}`,
    type: "UIElement",
    elementType: "Card",
    position: position.toString(),
    page: {
      "@id": topic
        ? getId(NEXT_PUBLIC_PULSE_CLIENT_ID, type, view, topic)
        : getId(NEXT_PUBLIC_PULSE_CLIENT_ID, type, view),
      url: topic
        ? getUrl(view, NEXT_PUBLIC_DOMAIN, asPath, topic)
        : getUrl(view, NEXT_PUBLIC_DOMAIN, asPath),
      "@type": type
    }
  };
};

export function trackThemeBoxImpression(
  pulseSdk: any,
  {
    NEXT_PUBLIC_PULSE_CLIENT_ID,
    NEXT_PUBLIC_DOMAIN,
    resource,
    pathname,
    position,
    asPath,
    topic
  }: {
    NEXT_PUBLIC_PULSE_CLIENT_ID: string;
    NEXT_PUBLIC_DOMAIN: string;
    resource: BoxHeading;
    pathname: string;
    asPath: string;
    position: number;
    topic?: Topic;
    loggedIn: boolean;
    user_id: string;
  }
) {
  const parsedId = _.deburr(resource.title).replaceAll(/\s/g, "").toLowerCase();
  pulseSdk?.newPageView();
  pulseSdk?.track("trackerEvent", {
    type: "View",
    schema: "http://schema.schibsted.com/events/tracker-event.json/288.json",
    object: getObject(
      NEXT_PUBLIC_PULSE_CLIENT_ID,
      NEXT_PUBLIC_DOMAIN,
      pathname,
      asPath,
      resource,
      position + 1,
      parsedId,
      topic
    ),
    target: {
      id: parsedId,
      type: "ThemeBox",
      name: resource.title
    }
  });
}
