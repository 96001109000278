import type { Article, Url } from "../../../client";

export function trackOpenExternalEvent(
  pulseSdk: any,
  {
    NEXT_PUBLIC_PULSE_CLIENT_ID,
    article,
    url,
    route,
    index
  }: {
    NEXT_PUBLIC_PULSE_CLIENT_ID: string;
    article: Article;
    url: Url;
    route: string;
    index?: number;
  }
) {
  const articleId = article.article_id;
  const articleSponsor = article.sponsor;
  const articleTitle = article.title.value;

  pulseSdk?.track("trackerEvent", {
    type: "Engagement",
    schema: "http://schema.schibsted.com/events/engagement-event.json/187.json",
    action: "Click",
    object: {
      type: "RedirectUrl",
      url: route,
      "spt:custom": {
        article_id: articleId,
        article_title: articleTitle,
        sponsor: articleSponsor || null,
        is_sponsored: !!articleSponsor,
        omni_key: url.omni_key,
        paywall: url.paywall,
        source: url.source,
        index
      }
    }
  });
}
