import React from "react";
import Head from "next/head";

import { getConfig } from "../../config";

const AppleTouchIcons = () => {
  const { NEXT_PUBLIC_NEWSROOM } = getConfig();
  return (
    <Head>
      <link
        rel="apple-touch-icon-precomposed"
        sizes="57x57"
        href={`/${NEXT_PUBLIC_NEWSROOM}/img/app-icons/apple-touch-icon-57x57.png`}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="114x114"
        href={`/${NEXT_PUBLIC_NEWSROOM}/img/app-icons/apple-touch-icon-114x114.png`}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="72x72"
        href={`/${NEXT_PUBLIC_NEWSROOM}/img/app-icons/apple-touch-icon-72x72.png`}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="144x144"
        href={`/${NEXT_PUBLIC_NEWSROOM}/img/app-icons/apple-touch-icon-144x144.png`}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="60x60"
        href={`/${NEXT_PUBLIC_NEWSROOM}/img/app-icons/apple-touch-icon-60x60.png`}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="120x120"
        href={`/${NEXT_PUBLIC_NEWSROOM}/img/app-icons/apple-touch-icon-120x120.png`}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="76x76"
        href={`/${NEXT_PUBLIC_NEWSROOM}/img/app-icons/apple-touch-icon-76x76.png`}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="152x152"
        href={`/${NEXT_PUBLIC_NEWSROOM}/img/app-icons/apple-touch-icon-152x152.png`}
      />
    </Head>
  );
};

export { AppleTouchIcons };
