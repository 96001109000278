import type { Article } from "../../../client";

import { createTrackedArticle, countDuration } from "../articleTracking";

export function trackArticleLeaveEvent(
  pulseSdk: any,
  {
    NEXT_PUBLIC_PULSE_CLIENT_ID,
    NEXT_PUBLIC_DOMAIN,
    NEXT_PUBLIC_PREMIUM_NEWSROOM,
    article,
    route,
    loggedIn,
    user_id
  }: {
    NEXT_PUBLIC_PULSE_CLIENT_ID: string;
    NEXT_PUBLIC_DOMAIN: string;
    NEXT_PUBLIC_PREMIUM_NEWSROOM: boolean;
    article: Article;
    route: string;
    loggedIn: boolean;
    user_id: string;
  }
) {
  if (!article.sponsor) {
    return;
  }

  const articleId = article.article_id;

  pulseSdk?.newPageView();
  pulseSdk?.track("trackerEvent", {
    type: "Leave",
    schema: "http://schema.schibsted.com/events/tracker-event.json/288.json",
    engagement: {
      duration: countDuration(articleId),
      activityDuration: countDuration(articleId)
    },
    object: createTrackedArticle(
      article,
      route,
      NEXT_PUBLIC_DOMAIN,
      NEXT_PUBLIC_PREMIUM_NEWSROOM,
      loggedIn,
      user_id
    )
  });
}
