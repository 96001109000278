import url from "url";

import type { ParsedUrlQueryInput } from "querystring";
import type {
  ColorPalette,
  Decoration,
  TextStyle
} from "../../client/ContentApi/models/decoration";
import type { CustomGroup } from "../../contexts/TeaserGroupContext";

import { rgbaToCss } from "../../client/ContentApi/models/decoration";

export function usermixToQueryObject(usermix?: { [key: string]: number }) {
  if (!usermix) return {};

  return Object.keys(usermix)
    .sort()
    .reduce(
      (acc, category_id) => ({
        ...acc,
        [`usermix[${category_id}]`]: usermix[category_id]
      }),
      {}
    );
}

export function removeUndefinedValues(
  query?: ParsedUrlQueryInput
): ParsedUrlQueryInput {
  if (!query) {
    return {};
  }
  return Object.keys(query)
    .filter(key => query[key] !== undefined)
    .reduce((acc, key) => ({ ...acc, [key]: query[key] }), {});
}

export function getQueryUrl(
  endpoint: string,
  query: ParsedUrlQueryInput | undefined
) {
  const parsedUrl = url.parse(endpoint, true);
  const formatedUrl = url.format({
    ...parsedUrl,
    query: removeUndefinedValues(query)
  });

  return formatedUrl;
}

export type CSSPropertiesMap = { [key: string]: React.CSSProperties };

type ThemeKeys = "dark" | "light";

function getColorPallete(
  theme: ThemeKeys,
  paletteMap: { [k in ThemeKeys]: ColorPalette }
) {
  const useTheme = paletteMap[theme];

  const properties = Object.keys(useTheme) as (keyof ColorPalette)[];
  return properties.reduce((acc, key) => {
    const cssProperty = rgbaToCss(useTheme[key]);
    if (cssProperty) {
      acc[`--${key}`] = cssProperty;
    }
    return acc;
  }, {} as CSSPropertiesMap);
}
const properties = [
  "font-family",
  "letter-spacing",
  "line-height",
  "font-size",
  "font-weight",
  "text-decoration",
  "padding"
];
function getTextStyles(styles: { [from in TextStyle]?: TextStyle }) {
  const toOverride = Object.keys(styles) as TextStyle[];
  return toOverride.reduce((acc, override) => {
    const use = styles[override];
    if (use) {
      properties.forEach(prop => {
        const toValue = `--${prop}--${override}`;
        const fromValue = `var(--${prop}--${use})`;
        acc[toValue] = fromValue as React.CSSProperties;
      });
    }
    return acc;
  }, {} as CSSPropertiesMap);
}

export function getDecorationProperties(
  decoration?: Decoration,
  additionalStyles?: { [key: string]: string }
): CSSPropertiesMap {
  const palette = decoration?.color_palette
    ? getColorPallete("light", decoration.color_palette)
    : {};
  const textStyles = decoration?.text_styles
    ? getTextStyles(decoration.text_styles)
    : {};
  return {
    ...palette,
    ...textStyles,
    ...(additionalStyles as CSSPropertiesMap)
  };
}

export function isThemeBox(group: CustomGroup) {
  return group[0]?.type === "BoxHeading";
}

export function isDynamicBox(group: CustomGroup) {
  return group[0]?.type === "Box";
}

export function getDynamicBoxName(group: CustomGroup) {
  if (group[0]?.type !== "Box") {
    return "";
  }

  return group[0]?.name || "";
}
