import { capitalize } from "@mui/material/utils";

import type { Article, SalesposterView } from "../../../client";

const getTarget = (article?: Article) => {
  if (article) {
    return {
      "@id": `sdrn:omnise:article:${article.article_id}`,
      "@type": "Article",
      name: article.title.value,
      category: article.category.title
    };
  }

  return undefined;
};

const getUrl = (domain: string) => {
  switch (domain) {
    case "omni.se":
      return "https://om.omni.se/omni-mer";
    case "omniekonomi.se":
      return "https://om.omni.se/ekonomi";
    default:
      return undefined;
  }
};

const getSPTCustom = (article?: Article) => {
  const sptCustom: { [key: string]: any } = {};

  if (article?.article_layout) {
    sptCustom.article_format = article?.article_layout;
  }

  if (article?.story_vignette?.suffix) {
    sptCustom.vignette_type = article?.story_vignette?.suffix;
  }

  if (Object.keys(sptCustom).length === 0) {
    return undefined;
  }

  return sptCustom;
};

export function trackPremiumSalesPosterEvent(
  pulseSdk: any,
  {
    NEXT_PUBLIC_PULSE_CLIENT_ID,
    NEXT_PUBLIC_DOMAIN,
    article,
    plan
  }: {
    NEXT_PUBLIC_PULSE_CLIENT_ID: string;
    NEXT_PUBLIC_DOMAIN: string;
    article?: Article;
    plan?: SalesposterView;
  }
) {
  const UIElement =
    plan === "omni" || plan === "omnipaketet" ? "billingfrequency" : "premium";

  pulseSdk?.newPageView();
  pulseSdk?.track("trackerEvent", {
    schema: "http://schema.schibsted.com/events/tracker-event.json/288.json",
    "@type": "View",
    object: {
      "spt:custom": getSPTCustom(article),
      name: "Premium Sales Poster",
      "@type": "SalesPoster",
      salesPosterType: "Paid",
      url: getUrl(NEXT_PUBLIC_DOMAIN),
      "@id": `sdrn:${NEXT_PUBLIC_PULSE_CLIENT_ID}:salesposter:${UIElement}`,
      target: getTarget(article),
      plan
    }
  });
}

export function trackBuySubscriptionEvent(
  pulseSdk: any,
  {
    NEXT_PUBLIC_PULSE_CLIENT_ID,
    subscriptionPlan,
    subscriptionType,
    adFree
  }: {
    NEXT_PUBLIC_PULSE_CLIENT_ID: string;
    subscriptionPlan: string;
    subscriptionType: string;
    adFree: boolean;
  }
) {
  pulseSdk?.track("trackerEvent", {
    type: "Engagement",
    schema: "http://schema.schibsted.com/events/engagement-event.json/254.json",
    action: "Click",
    object: {
      "@id": `sdrn:${NEXT_PUBLIC_PULSE_CLIENT_ID}:product:salesposter:element:start_trial_period`,
      "@type": "UIElement",
      name: "start_trial_period",
      "spt:custom": {
        subscription_plan: subscriptionPlan,
        subscription_type: subscriptionType,
        ad_free: adFree
      }
    }
  });
}

export function trackFeatureCTAEvent(
  pulseSdk: any,
  {
    NEXT_PUBLIC_PULSE_CLIENT_ID,
    feature
  }: {
    NEXT_PUBLIC_PULSE_CLIENT_ID: string;
    feature: string;
  }
) {
  pulseSdk?.track("trackerEvent", {
    type: "Engagement",
    schema: "http://schema.schibsted.com/events/engagement-event.json/254.json",
    action: "Click",
    object: {
      "@id": `sdrn:${NEXT_PUBLIC_PULSE_CLIENT_ID}:product:salesposter:element:feature_cta`,
      "@type": "UIElement",
      name: "feature_cta",
      "spt:custom": {
        feature
      }
    }
  });
}

export function tracksalesPosterLoginEvent(
  pulseSdk: any,
  {
    NEXT_PUBLIC_PULSE_CLIENT_ID
  }: {
    NEXT_PUBLIC_PULSE_CLIENT_ID: string;
  }
) {
  pulseSdk?.track("trackerEvent", {
    type: "Engagement",
    schema: "http://schema.schibsted.com/events/engagement-event.json/254.json",
    action: "Click",
    object: {
      "@id": `sdrn:${NEXT_PUBLIC_PULSE_CLIENT_ID}:product:salesposter:element:log_in`,
      "@type": "UIElement",
      name: "log_in"
    }
  });
}

export function tracksalesPosterReadMoreEvent(
  pulseSdk: any,
  {
    NEXT_PUBLIC_PULSE_CLIENT_ID
  }: {
    NEXT_PUBLIC_PULSE_CLIENT_ID: string;
  }
) {
  pulseSdk?.track("trackerEvent", {
    type: "Engagement",
    schema: "http://schema.schibsted.com/events/engagement-event.json/254.json",
    action: "Click",
    object: {
      "@id": `sdrn:${NEXT_PUBLIC_PULSE_CLIENT_ID}:product:salesposter:element:read_more`,
      "@type": "UIElement",
      name: "read_more"
    }
  });
}

export function trackFeatureEvent(
  pulseSdk: any,
  {
    NEXT_PUBLIC_PULSE_CLIENT_ID,
    article,
    position
  }: {
    NEXT_PUBLIC_PULSE_CLIENT_ID: string;
    article?: Article;
    position: "first" | "last";
  }
) {
  pulseSdk?.newPageView();
  pulseSdk?.track("trackerEvent", {
    schema: "http://schema.schibsted.com/events/tracker-event.json/252.json",
    "@type": "View",
    object: {
      "@id": `sdrn:${NEXT_PUBLIC_PULSE_CLIENT_ID}:article:card:element:${position}feature`,
      type: "UIElement",
      elementType: "Card",
      "spt:custom": {
        article_id: article?.article_id
      },
      name: `${capitalize(position)}Feature`
    }
  });
}
