import type {
  AdPositions,
  AdRuleConfig,
  AdRuleWithFetchFrequency,
  DynamicalAdPlacementInfo,
  FullscreenSizes,
  Sizes,
  AstAdObj,
  AstApnTag,
  AstKeywordValue,
  EidsUserSource,
  RelevantYield
} from "@omni/ads";
import type { AdRuleType, Article } from "../../../../client";
import type { UserState } from "../../../../containers/GlobalStateContainer/userState/types";
import type { Permissions } from "../../../../server/lbmpService";

import { fetchTopicWithCache } from "../../../../client";
import { getConfig } from "../../../../config";
import { getPulseSdk } from "../../../../containers/TrackingContainer";
import { getCookie } from "../../../../helpers";
import { isServer } from "../../../../helpers/isServer";
import { isNewSession } from "../../../../lib/sessionMarker";
import { getTCF } from "../cookieConsent";
import {
  allowedPreBannerClasses,
  layoutNames,
  sharedVars,
  specialCharacters,
  triggerPoints
} from "./constants";
import { UseLocalStorage } from "../../../../hooks/UseLocalStorage";

export const sizeToArray = ({
  width,
  height
}: {
  width: number;
  height: number;
}) => [width, height];

const sizes: FullscreenSizes = {
  xl: (container: HTMLDivElement) => {
    const img = container.querySelector("img") as HTMLImageElement;

    const style: HTMLStyleElement = document.createElement("style");
    style.innerText =
      ".banner {width: 100vw; height: 100vh; background-repeat: no-repeat; background-position: center; background-size: cover;}";

    const div: HTMLDivElement = document.createElement("div");
    div.classList.add("banner");
    div.style.backgroundImage = "url(" + img.src + ")";

    img.parentNode?.replaceChild(div, img);
    container.appendChild(style);
    return container.innerHTML;
  }
};

const getContent = (targetId: string, appnexus: any) => {
  if (!appnexus) {
    return null;
  }
  return appnexus.requests.tags[targetId].adResponse.ads[0].rtb.banner.content;
};

const changeContent = (content: string, fullscreenSize: Sizes) => {
  try {
    const container: HTMLDivElement = document.createElement("div");
    container.innerHTML = content;

    return sizes[fullscreenSize](container);
  } catch (e) {
    console.error(e);
  }
  return content;
};

export const setCustomFullscreenStyles = (
  targetId: string,
  fullscreenSize: Sizes
) => {
  const appnexus = (window as any).apntag;
  try {
    appnexus.requests.tags[targetId].adResponse.ads[0].rtb.banner.content =
      changeContent(getContent(targetId, appnexus), fullscreenSize);
  } catch (e) {
    console.error(e);
  }
};

export function getApnTag(): Promise<AstApnTag> {
  return new Promise(resolve => {
    setTimeout(() => {
      const windowApnTag = window?.apntag;
      if (windowApnTag) {
        resolve(windowApnTag);
      } else {
        resolve(getApnTag());
      }
    }, 100);
  });
}

export function getBiddingSdk(): Promise<RelevantYield> {
  return new Promise(resolve => {
    setTimeout(() => {
      const biddingSdk = window?.relevantDigital;
      if (biddingSdk) {
        resolve(biddingSdk);
      } else {
        resolve(getBiddingSdk());
      }
    }, 100);
  });
}

export const getTopicName = async (topicId: string | undefined) => {
  if (!topicId) {
    return undefined;
  }

  const res = await fetchTopicWithCache({ topicId });

  return res.topic?.title;
};

export const getOffset = (id: string) => {
  for (const { placement, offset } of triggerPoints) {
    if (id.includes(placement)) {
      return { top: -offset / 2, right: 0, bottom: -offset, left: 0 };
    }
  }

  return { top: 0, right: 0, bottom: 0, left: 0 };
};

export const shouldNotShowTakeover = (position: string) =>
  ["takeoverDesktop", "takeoverMobile"].includes(position) && !isNewSession();

export const operateOnFrequency = (ad: AdRuleWithFetchFrequency): boolean => {
  const { position, fetch_frequency: frequencyControlHours } = ad.rules;
  const localStorageItemName = `${position}"_fetch_frequency"`;
  const localStorageItem = UseLocalStorage().get(localStorageItemName);
  const lastSeen = Number(localStorageItem || 0);
  const now = new Date().getTime();
  const timeSinceLastSeen = now - lastSeen;
  const frequencyLimit = frequencyControlHours * 1000 * 60 * 60;

  if (timeSinceLastSeen < frequencyLimit || shouldNotShowTakeover(position)) {
    return false;
  }

  UseLocalStorage().set(localStorageItemName, `${now}`);
  return true;
};

export const forceShow = (adRule: AdRuleType) => {
  if (isServer()) {
    return false;
  }

  const { rules } = adRule;
  const browser = window as Window;
  const params = new URLSearchParams(browser.location.search);

  let forceShow;
  if (["takeoverDesktop", "takeoverMobile"].includes(rules.position)) {
    forceShow = params.get("takeover");
  } else {
    forceShow = params.get("fullscreen");
  }

  return forceShow === "1";
};

export const frequencyControlFilter = (
  adRule?: AdRuleType
): AdRuleType | null => {
  if (!adRule) {
    return null;
  }

  try {
    const { rules } = adRule;
    if (
      ["fullscreen", "takeoverDesktop", "takeoverMobile"].includes(
        rules.position
      ) &&
      rules.fetch_frequency
    ) {
      if (forceShow(adRule)) {
        return adRule;
      }
      if (operateOnFrequency(adRule as AdRuleWithFetchFrequency)) {
        return adRule;
      } else {
        return null;
      }
    }
    return adRule;
  } catch (e) {
    console.error("Error inside frequencyControlFilter", e);
    return null;
  }
};

export const getPreBanneClassNames = (className: string) =>
  className
    .split(" ")
    .filter(c => allowedPreBannerClasses.includes(c))
    .join(" ");

const maybeContains =
  (value?: string) =>
  (array: string[]): boolean => {
    if (!array) {
      return true;
    }
    return array.indexOf(value || "") > -1;
  };

const maybeContainsAny =
  (values?: string | string[]) =>
  (array: string[]): boolean => {
    if (!array) {
      return true;
    }
    if (!values) {
      return false;
    }
    if (Array.isArray(values)) {
      return !!values.filter(value => array.includes(value)).length;
    } else {
      return maybeContainsAny([values])(array);
    }
  };

const maybeNotContainsAny =
  (values?: string | string[]) =>
  (array: string[]): boolean => {
    if (!array || !values) {
      return true;
    }
    if (Array.isArray(values)) {
      return !values.filter(value => array.includes(value)).length;
    } else {
      return maybeNotContainsAny([values])(array);
    }
  };

export const getAllowedAds = ({
  category,
  topics
}: {
  category?: string;
  topics?: string[];
}) => {
  const f: Array<
    ["categories" | "not_categories" | "topics" | "not_topics", any]
  > = [
    ["categories", maybeContains(category)],
    ["not_categories", maybeNotContainsAny(category)],
    ["topics", maybeContainsAny(topics)],
    ["not_topics", maybeNotContainsAny(topics)]
  ];
  return ({ rules }: Pick<AdRuleType, "rules">) => {
    return f.reduce((acc, [rulesKey, predicate]) => {
      return !acc ? false : predicate(rules[rulesKey]);
    }, true);
  };
};

export const getAdRule = (
  adRules: AdRuleType[],
  config: AdRuleConfig,
  placement: AdPositions
) => {
  const isAdAllowed = getAllowedAds(config);

  const adRulesForPosition = adRules.filter(
    adr => adr?.rules?.position === placement
  );

  const adRule = adRulesForPosition.find(adr => isAdAllowed(adr));

  return adRule;
};

const sanitizeValue = (val = "") =>
  val
    .replace(specialCharacters, "")
    .replace(/[åä]/g, "a")
    .replace(/[ö]/g, "o")
    .replace(/[ÅÄ]/g, "A")
    .replace(/[Ö]/g, "O")
    .replace(/&/g, "--")
    .replace(/[,/\s]/g, "-");

const sortedScreens = Object.keys(sharedVars)
  .map(key => ({ key, value: sharedVars[key] }))
  .filter(({ key }) => /^screen-/.test(key))
  .sort((a, b) => {
    if (a.value < b.value) {
      return -1;
    } else if (a.value > b.value) {
      return 1;
    } else {
      return 0;
    }
  });

const getCurrentBreakpoint = () => {
  const windowWidth = window.innerWidth;
  return sortedScreens.reduce((a, b) => (b.value <= windowWidth ? b : a), {
    key: "screen-xs-min",
    value: 0
  }).key;
};

export const getDynamicalPlacementInfo = (): DynamicalAdPlacementInfo => {
  if (getCurrentBreakpoint().includes("xs")) {
    return {
      classnames: "hidden-sm hidden-md hidden-lg hidden-xl banner",
      placement: "dynamicMobileWeb",
      idPrefix: "banner-dynamic-mobile-web"
    };
  }

  return {
    classnames: "hidden-xs banner",
    placement: "dynamicDesktop",
    idPrefix: "banner-dynamic-desktop"
  };
};

export const triggerPrivacyManager = async () => {
  const cookieConsent = await getTCF();
  return cookieConsent.showPrivacyManager();
};

export const getLayoutName = () => {
  if (isServer()) {
    return;
  }
  return layoutNames[getCurrentBreakpoint()];
};

const getSupplyType = () => {
  const layout = layoutNames[getCurrentBreakpoint()];
  return `web_${layout === "mobile" ? "phone" : layout}`;
};
/** Taken from https://github.schibsted.io/spt-dataanalytics/event-sdk-js/blob/master/docs/docs/ADID.md
 */
export async function getAdId() {
  if (!document) return;
  return getPulseSdk(pulse => {
    return pulse.getAdId();
  });
}

const getPPIDs = async (): Promise<EidsUserSource[]> => {
  return getPulseSdk(async pulse => {
    try {
      const [PPID1, PPID2] = await Promise.all([
        pulse.getXandrPPID1(),
        pulse.getXandrPPID2()
      ]);

      return PPID1
        ? [
            { id: PPID1, source: "SCHSE-UserHash" },
            { id: PPID2, source: "SCHSE-EnvHash" }
          ]
        : [{ id: PPID2, source: "SCHSE-EnvHash" }];
    } catch (e) {
      console.error(e);
    }

    return [];
  });
};

export const getUserIds = async () => {
  const ppIds = await getPPIDs();
  const eids = [];

  if (ppIds?.some(ppid => ppid.source === "SCHSE-EnvHash")) {
    const PPID2 = ppIds.filter(ppid => ppid.source === "SCHSE-EnvHash");

    eids.push({
      source: "SCHNO-EnvHash",
      uids: [
        {
          id: PPID2[0].id,
          atype: 1,
          ext: {
            stype: "ppuid"
          }
        }
      ]
    });
  }
  if (ppIds?.some(ppid => ppid.source === "SCHSE-UserHash")) {
    const PPID1 = ppIds.filter(ppid => ppid.source === "SCHSE-UserHash");

    eids.push({
      source: "SCHNO-UserHash",
      uids: [
        {
          id: PPID1[0].id,
          atype: 1,
          ext: {
            stype: "ppuid"
          }
        }
      ]
    });
  }

  return [
    {
      name: "pubProvidedId",
      params: {
        eids: eids
      }
    }
  ];
};

export const getPPIDWithTagValues = async (): Promise<
  [EidsUserSource[], Record<string, AstKeywordValue>]
> => {
  const ppIds = await getPPIDs();

  const ppidTags = { "aa-sch-schuserhash": 0, "aa-sch-schenvhash": 0 };

  if (ppIds?.some((ppid: any) => ppid.source === "SCHSE-UserHash")) {
    ppidTags["aa-sch-schuserhash"] = 1;
  }

  if (ppIds?.some((ppid: any) => ppid.source === "SCHSE-EnvHash")) {
    ppidTags["aa-sch-schenvhash"] = 1;
  }

  return [ppIds, ppidTags];
};

const getGenericExternalId = ({
  adId,
  loggedIn
}: {
  adId?: string;
  loggedIn: boolean;
}): number => {
  if (!adId) return 0;
  if (!loggedIn) return 1;
  return 2;
};

const windowKeyWords = (() => {
  if (typeof window === "undefined") return {};
  const entries = new URLSearchParams(window.location.search).entries();
  const acc: { [key: string]: string } = {};
  let kv: IteratorResult<[string, string]>;
  while ((kv = entries.next())) {
    if (kv.done) {
      break;
    }
    const [key, val] = (kv.value || []) as [string?, string?];
    if (val && key?.startsWith("kv_")) {
      acc[key.substring(3)] = val;
    }
  }
  return acc;
})();

export const getKeywords = async ({
  user,
  topicName,
  geoLocationTracking = false,
  article,
  consentToAll
}: {
  user: UserState;
  topicName?: string;
  geoLocationTracking?: boolean;
  article?: Article;
  consentToAll: boolean | null;
}) => {
  const {
    translation: { ads_publisher },
    appnexus
  } = getConfig();

  const [apntag, adId] = await Promise.all([getApnTag(), getAdId()]);

  // Using article.type to check if the object is empty. HATE THIS
  const articleSpecific = article?.type
    ? {
        "se-generic-page": "article",
        "se-generic-articleid": article.article_id,
        "se-generic-story": sanitizeValue(
          article.story ? article.story.title : undefined
        ),
        "se-generic-tags": article.tags.map(tag => sanitizeValue(tag.title)),
        "se-generic-topic": sanitizeValue(article.title.value),
        "se-omni-section": sanitizeValue(article.category.title),
        "se-generic-section": sanitizeValue(article.category.title),
        ...(article.ad_keywords || {}),
        ...(appnexus ? appnexus.override : {})
      }
    : {};

  const geoLocationSpecific = geoLocationTracking
    ? {
        "se-generic-audience": (getCookie("nPsegs") || "").split("|")
      }
    : {};

  const topicSpecific = topicName
    ? {
        "se-generic-topic": sanitizeValue(topicName)
      }
    : {};

  return {
    "se-generic-sdk-version":
      apntag && typeof apntag.getAstVersion === "function"
        ? apntag.getAstVersion()
        : "",
    "se-generic-page": "index",
    "aa-sch-country_code": "se",
    "aa-sch-publisher": `${ads_publisher}`,
    "aa-sch-supply_type": `${getSupplyType()}`,
    "aa-sch-cmp_advertising": consentToAll ? "1" : "0",
    "se-generic-appmode": "notinapp",
    "se-generic-pagegen": "smp",
    "se-generic-loggedin": `${user?.isLoggedIn}`,
    "se-generic-premium": `${user?.subscriptionsInfo.isPremium}`,
    "se-generic-sitemode": getLayoutName(),
    "se-generic-screenwidth": window.outerWidth,
    "se-generic-screenheight": window.outerHeight,
    "se-generic-viewportwidth": window.innerWidth,
    "se-generic-viewportheight": window.innerHeight,
    "se-generic-externalid_src": getGenericExternalId({
      adId,
      loggedIn: user.isLoggedIn
    }),
    ...geoLocationSpecific,
    ...articleSpecific,
    ...windowKeyWords,
    ...topicSpecific
  };
};

export const getAdFormats = ({
  adFormats
}: {
  adFormats?: string[];
  marketingConsent?: boolean | null;
}) => {
  if (!adFormats || adFormats.length === 0) {
    return [];
  }

  return adFormats;
};

export const isNativeBanner = (e: AstAdObj) => e.adType === "banner";

export const isNativeAd = (e: AstAdObj) => e.adType === "native";

export const getGeoTrackingPermission = (permissions: Permissions) => {
  if (permissions.length > 0) {
    return permissions
      .reduce((permissionsArr: string[], permission) => {
        return [...permissionsArr, ...permission.data];
      }, [])
      .some(
        permission =>
          permission === "sdrn:schibsted-media:datacategory:geolocation"
      );
  }

  return false;
};
