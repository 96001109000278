import React from "react";

export const preLoadFonts = (newsroom: string, fonts: string[]) =>
  fonts.map(font => (
    <link
      rel="preload"
      href={`/fonts/${newsroom}/${font}`}
      as="font"
      crossOrigin=""
      key={font}
    />
  ));
