import type { Inscription } from "./inscription";
import type { Block } from "./block";
/**
 * Omni Article Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type BlockQuote = {
  block_type: "BlockQuote" | "quote";
  text: Inscription;
};
export function isBlockQuote(block: Block): block is BlockQuote {
  return block.block_type === "BlockQuote" || block.block_type === "quote";
}
