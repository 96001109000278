import type { TopicsResponse } from "../models";

import { ApiClient } from "../../apiClient";

export type topicSort = "current" | "popular";

export type GetTopicsParams = {
  offset?: number;
  limit?: number;
  sort: topicSort;
  where_type?: string;
  where_not_type?: string;
  signal?: AbortSignal;
};

function errorHandling(e: Error, signal?: AbortSignal): null {
  if (signal?.aborted) {
    return null;
  } else {
    throw e;
  }
}

export function fetchTopicsWithCache({
  offset = 0,
  limit = 5,
  sort,
  where_type,
  where_not_type,
  signal
}: GetTopicsParams): Promise<TopicsResponse> {
  const makeReq = new ApiClient("GET", "content");

  const query = {
    offset,
    limit,
    sort,
    where_type,
    where_not_type
  };
  return makeReq
    .getWithCache("/topics", { query, signal })
    .catch(e => errorHandling(e, signal));
}

export function fetchTopicWithCache({
  topicId,
  signal
}: {
  topicId: string;
  signal?: AbortSignal;
}) {
  const makeReq = new ApiClient("GET", "content");

  return makeReq
    .getWithCache(`/topics/${topicId}`, { signal })
    .catch(e => errorHandling(e, signal));
}
