import { ApiClient } from "../../apiClient";

export async function sessionsDelete(signal?: AbortSignal) {
  const settingsClient = new ApiClient("DELETE", "settings");
  return settingsClient.send("/sessions", {
    mode: "cors",
    credentials: "include",
    signal
  });
}
