import omniConfig from "./omni.json";
import omniekoConfig from "./omnieko.json";

export * from "./getConfig";
export function getConfigForNewsRoom(newsroom?: string) {
  switch (newsroom) {
    case "omni":
      return omniConfig;
    case "omnieko":
      return omniekoConfig;
    default:
      throw new Error(`Invalid newsroom ${newsroom}`);
  }
}
