import type { Article } from "../../../client";

import { getArticleTopicObject } from "../articleTracking";

export function trackUnfavEvent(
  pulseSdk: any,
  {
    NEXT_PUBLIC_PULSE_CLIENT_ID,
    article,
    topicId
  }: { NEXT_PUBLIC_PULSE_CLIENT_ID: string; article: Article; topicId: string }
) {
  pulseSdk?.track(
    "trackerEvent",
    getArticleTopicObject({
      name: "TopicStar",
      topicId,
      article,
      pulseId: NEXT_PUBLIC_PULSE_CLIENT_ID
    })
  );
}
