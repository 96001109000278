import { create } from "zustand";

import type { UserSlice } from "./userState/types";
import type { SiteSlice } from "./siteState/types";

import { createUserSlice } from "./userState/createUserSlice";
import { createSiteSlice } from "./siteState/createSiteSlice";

type GlobalStore = UserSlice & SiteSlice;

const useGlobalStore = create<GlobalStore>()((setState, getState, store) => ({
  ...createUserSlice(setState, getState, store),
  ...createSiteSlice(setState, getState, store)
}));

export { useGlobalStore };
