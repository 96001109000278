import type { SalesposterResponse } from "../models/salesposterType";

import { ApiClient } from "../../apiClient";

export async function fetchSalesposterData(
  signal?: AbortSignal
): Promise<SalesposterResponse> {
  const makeReq = new ApiClient("GET", "salesposter");
  return makeReq.getWithCache("/salesposter", { signal }, "json");
}
