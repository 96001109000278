import { localStore } from "./storageFactory";

const set = <T>(key: string, payload: T): void => {
  localStore.setItem(key, JSON.stringify(payload));
};

const get = <T>(key: string): T | null => {
  const item = localStore.getItem(key);

  if (item) {
    return JSON.parse(item);
  }

  return null;
};

const remove = (key: string): void => {
  localStore.removeItem(key);
};

const clear = () => localStore.clear();

const UseLocalStorage = () => ({
  get,
  set,
  remove,
  clear
});

export { UseLocalStorage };
