import { LRUCache } from "lru-cache";
import fetchRetry from "fetch-retry";

import type { ParsedUrlQueryInput } from "querystring";

import { getQueryUrl } from "./helpers";
import { PremiumStateError } from "./PremiumStateError";
import { getConfig } from "../config";
import { Error404, Error500 } from "../lib/errors";
import { isServer } from "../helpers/isServer";
import { getIdentityInstance } from "../containers/GlobalStateContainer/userState/utils/getIdentityInstance";
import { logout } from "../containers/GlobalStateContainer/userState/actions/logout";

const CONTENT_API_URL = process.env.NEXT_PUBLIC_CONTENT_API_BASE || "";

const SETTINGS_API_URL = process.env.NEXT_PUBLIC_SETTINGS_API_BASE || "";

const NEXT_PUBLIC_ADS_API_BASE = process.env.NEXT_PUBLIC_ADS_API_BASE || "";

const EIC_URL = process.env.EIC_URL || "";

const SALESPOSTER_API_BASE = process.env.NEXT_PUBLIC_CONTENT_API_BASE || ""; // temporary fetch data from content api until salesposter service is up

const defaultMaxAgeInMS = 1000 * 60 * 6; // 6 minutes;
const cache = new LRUCache({
  max: 500,
  ttl: defaultMaxAgeInMS
});

type QueryType = {
  query?: ParsedUrlQueryInput;
  exchangeToken?: string;
  signal?: AbortSignal;
};

type MethodType = "PUT" | "DELETE" | "GET" | "PATCH" | "POST";

type ApiType = "content" | "settings" | "ads" | "eic" | "salesposter";

type Options = Omit<RequestInit, "method"> & QueryType;

const fetch = fetchRetry(global.fetch, {
  retries: 5,
  retryDelay: 100,
  retryOn: async (attempt, error, response) => {
    if (response?.status === 401 && attempt === 1) {
      const indentifyInstance = getIdentityInstance();
      if (indentifyInstance) {
        const session = await indentifyInstance.hasSession();
        if ("error" in session) {
          console.error(session.error.description);
          return false;
        } else {
          return true;
        }
      }
    }

    return (
      (error !== null && error.name !== "AbortError") ||
      (response?.status !== undefined && response.status >= 500)
    );
  }
});

export class ApiClient {
  method: MethodType;
  baseUrl: string;
  type: ApiType;

  constructor(method: MethodType, apiType: ApiType) {
    this.method = method;
    this.type = apiType;
    switch (apiType) {
      case "content":
        this.baseUrl = CONTENT_API_URL;
        break;
      case "settings":
        this.baseUrl = SETTINGS_API_URL;
        break;
      case "ads":
        this.baseUrl = NEXT_PUBLIC_ADS_API_BASE;
        break;
      case "eic":
        this.baseUrl = EIC_URL;
        break;
      case "salesposter":
        this.baseUrl = SALESPOSTER_API_BASE;
    }
  }

  async send(endpoint: string, opts: Options = {}, format: string = "json") {
    const formattedUrl = getQueryUrl(endpoint, opts.query);
    const url = this.baseUrl + formattedUrl;
    const headers: { [key: string]: string } = {};
    if (isServer()) {
      const config = getConfig();
      headers["user-agent"] = `Web-${config.NEXT_PUBLIC_NEWSROOM}/1.0`;
    }

    if (opts.exchangeToken) {
      headers["authorization"] = `Exchange-Bearer ${opts.exchangeToken}`;
      headers["x-omni-verify-premium"] = "true";
    }

    const res = await fetch(url, {
      headers,
      ...opts,
      method: this.method
    });

    if (res.status >= 200 && res.status <= 299) {
      if (format === "json") {
        return res.json();
      }

      return res.text();
    }

    if (res.status === 401) {
      if (this.type === "content") {
        await logout();
      }
      // TODO Here we have to handle error on the top and logout/relog the user
      throw new PremiumStateError("Unauthorized", res);
    }

    if (res.status === 403) {
      throw new PremiumStateError("Forbidden", res);
    }

    if (res.status === 404) {
      throw new Error404();
    }

    if (res.status === 500) {
      throw new Error500(
        `${res.statusText || "something went wrong"},  endpoint ${endpoint}`
      );
    }
  }

  async getWithCache(
    endpoint: string,
    opts: Options = {},
    format: string = "json",
    ttl = defaultMaxAgeInMS
  ) {
    const queryUrl = getQueryUrl(endpoint, opts.query);
    const data = cache.get(queryUrl);
    if (data) {
      return data;
    }

    try {
      const freshData = await this.send(endpoint, opts, format);
      cache.set(queryUrl, freshData, { ttl });
      return freshData;
    } catch (e) {
      throw new Error(
        `Could not get fresh data for endpoint: ${endpoint}. ${e}`
      );
    }
  }
}
