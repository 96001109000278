export interface MessageType {
  id: string;
  text: string;
  showCloseButton?: boolean;
  hideAfter?: number;
}

export interface CustomEvent<T> extends Event {
  detail: T;
}

export interface CustomEventListener<T> {
  (evt: CustomEvent<T>): void;
}

export type MessageEventType<T = MessageType> = CustomEvent<CustomEvent<T>>;

export function showMessage(message: MessageType) {
  document.dispatchEvent(
    new CustomEvent("message", {
      detail: message
    })
  );
}
