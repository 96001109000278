import React, { useEffect, useState } from "react";
import NextHead from "next/head";
import { useRouter } from "next/router";

import { preLoadFonts } from "./FontsPreload";
import { setNewSession } from "../lib/sessionMarker";
import { getConfig } from "../config";
import { UseLocalStorage } from "../hooks/UseLocalStorage";

export function Head() {
  const {
    NEXT_PUBLIC_NEWSROOM,
    NEXT_PUBLIC_DOMAIN,
    NEXT_PUBLIC_SETTINGS_API_BASE,
    translation,
    fonts
  } = getConfig();

  const router = useRouter();
  const [initialRequest, setInitialRequest] = useState(true);

  useEffect(() => {
    setNewSession(initialRequest);
  }, [initialRequest]);

  useEffect(() => {
    router.events.on("routeChangeStart", () => {
      setInitialRequest(false);
    });
  }, []);

  useEffect(() => {
    const removeInitialRequest = (e: BeforeUnloadEvent) => {
      UseLocalStorage().remove("initalRequest");
      return;
    };

    window.addEventListener("beforeunload", removeInitialRequest);
    router.events.on("routeChangeStart", removeInitialRequest);
  }, []);
  return (
    <NextHead>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>{translation.title}</title>
      <meta name="description" content={`${translation.dbtitle}`} />
      {preLoadFonts(NEXT_PUBLIC_NEWSROOM, fonts)}
      <link
        rel="search"
        type="application/opensearchdescription+xml"
        href={`/${NEXT_PUBLIC_NEWSROOM}/opensearch.xml`}
        title={translation.application_name}
      />
      <meta
        property="article:publisher"
        content={translation.article_publisher}
      />
      <link rel="icon" href={`/${NEXT_PUBLIC_NEWSROOM}/img/favicon.ico`} />
      <meta
        name="google-play-app"
        content={`app-id=${translation.app_id.google}`}
      />
      <meta
        name="apple-itunes-app"
        content={`app-id=${translation.app_id.apple}, app-argument=https://${NEXT_PUBLIC_DOMAIN}`}
      />
      <meta
        name="application-name"
        content={`${translation.application_name}`}
      />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta
        name="msapplication-TileImage"
        content={`/${NEXT_PUBLIC_NEWSROOM}/img/app-icons/mstile-144x144.png`}
      />
      <meta
        name="msapplication-square70x70logo"
        content={`/${NEXT_PUBLIC_NEWSROOM}/img/app-icons/mstile-70x70.png`}
      />
      <meta
        name="msapplication-square150x150logo"
        content={`/${NEXT_PUBLIC_NEWSROOM}/img/app-icons/mstile-150x150.png`}
      />
      <meta
        name="msapplication-wide310x150logo"
        content={`/${NEXT_PUBLIC_NEWSROOM}/img/app-icons/mstile-310x150.png`}
      />
      <meta
        name="msapplication-square310x310logo"
        content={`${NEXT_PUBLIC_NEWSROOM}/img/app-icons/mstile-310x310.png`}
      />
      <link rel="dns-prefetch" href="https://id.omni.se" />
      <link rel="dns-prefetch" href={NEXT_PUBLIC_SETTINGS_API_BASE} />
      <link rel="dns-prefetch" href="https://trafficgateway.research-int.se" />
      <link rel="dns-prefetch" href="https://secure.adnxs.com" />
      <link rel="dns-prefetch" href="https://cis.schibsted.com" />
      <link rel="dns-prefetch" href="https://static.privacy.schibsted.com" />
      <link rel="dns-prefetch" href="https://platform.twitter.com" />
      <link rel="preconnect" href="https://cmp.omni.se" />
      <link rel="dns-prefetch" href="https://acdn.adnxs.com" />
      <link
        rel="dns-prefetch"
        href="https://region-api.inventory.schibsted.io"
      />
    </NextHead>
  );
}
