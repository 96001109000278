import { ApiClient } from "../../apiClient";
import { type SpidSettings } from "../models/spidSettings";

export function getSpidProfile(signal?: AbortSignal): Promise<SpidSettings> {
  const settingsClient = new ApiClient("GET", "settings");

  return settingsClient.send("/spid/me", {
    mode: "cors",
    credentials: "include",
    signal
  });
}
