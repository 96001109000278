import { createContext } from "react";

import type { Article, Group } from "../client";

interface ArticleContextType {
  article: Article;
  loadStoryArticles?: () => Promise<Array<Group>>;
  lazy: boolean;
}

const defaultValue: ArticleContextType = {
  article: {} as Article,
  loadStoryArticles: async () => [] as Group[],
  lazy: false
};

export const ArticleContext = createContext(defaultValue);
