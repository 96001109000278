import type { PersonalizedContent, UserInfo, UserState } from "./types";

const initialUserState: UserState = {
  isLoggedIn: false,
  exchangeToken: "",
  userInfo: {} as UserInfo,
  subscriptionsInfo: {
    isPremium: false,
    isAdFree: false
  },
  personalizedContent: {} as PersonalizedContent,
  permissions: [],
  dataFetchingResults: {
    info: false,
    premium: false,
    personalizedContent: false
  }
};

export { initialUserState };
