import type { Article } from "../../../client";

import { trackArticleViewEvent } from "./trackArticleViewEvent";
import { getPulseSdk } from "../index";

export function trackViewArticle({
  article,
  route,
  isLoggedIn,
  referrer,
  user_id,
  NEXT_PUBLIC_PREMIUM_NEWSROOM,
  NEXT_PUBLIC_DOMAIN
}: {
  article: Article;
  route: string;
  isLoggedIn: boolean;
  referrer: string;
  user_id: string;
  NEXT_PUBLIC_PREMIUM_NEWSROOM: boolean;
  NEXT_PUBLIC_DOMAIN: string;
}) {
  getPulseSdk(tracker => {
    trackArticleViewEvent(tracker, {
      NEXT_PUBLIC_DOMAIN,
      NEXT_PUBLIC_PREMIUM_NEWSROOM,
      article,
      route,
      isLoggedIn,
      user_id,
      referrer
    });
  });
}
