import { Identity } from "@schibsted/account-sdk-browser";

import { getConfig } from "../../../../config";

const getIdentityInstance = () => {
  const {
    NEXT_PUBLIC_SPID_CLIENT_ID,
    NEXT_PUBLIC_SPID_COOKIE_DOMAIN,
    NEXT_PUBLIC_SPID_REDIRECT_URL,
    NEXT_PUBLIC_SPID_ENV
  } = getConfig();

  return typeof window !== "undefined" && Identity
    ? new Identity({
        clientId: NEXT_PUBLIC_SPID_CLIENT_ID,
        redirectUri: NEXT_PUBLIC_SPID_REDIRECT_URL,
        env: NEXT_PUBLIC_SPID_ENV || "PRO",
        sessionDomain: NEXT_PUBLIC_SPID_COOKIE_DOMAIN
      })
    : undefined;
};

export { getIdentityInstance };
