import type { StateCreator } from "zustand";
import type { Salesposter } from "../../../client";
import type { SiteSlice } from "./types";

const createSiteSlice: StateCreator<SiteSlice> = (set, get) => ({
  categories: [],
  allCategories: [],
  adRules: [],
  collapsedAdIds: [],
  salesposter: {} as Salesposter,
  setFullSiteState: fullState => {
    set({ ...fullState });
  },
  setCollapsedAdId: collapsedAdId => {
    set({
      collapsedAdIds: [...get().collapsedAdIds, collapsedAdId]
    });
  }
});

export { createSiteSlice };
