import Script from "next/script";

import { getConfig } from "../../config";

const { NEXT_PUBLIC_PREBID_CONFIG_ID, NEXT_PUBLIC_PREBID_TAG_ID } = getConfig();

const relevantStaticScript = `https://schibsted-cdn.relevant-digital.com/static/tags/${NEXT_PUBLIC_PREBID_TAG_ID}.js`;

const relevantDigitalPrebidScript = `(function() {
  window.relevantDigital = window.relevantDigital || {};
  relevantDigital.cmd = relevantDigital.cmd || [];
  relevantDigital.cmd.push(function() {
      relevantDigital.loadPrebid({
          configId: "${NEXT_PUBLIC_PREBID_CONFIG_ID}",
          manageAdserver: true,
          collapseEmptyDivs: true,
          collapseBeforeAdFetch: false,
          allowedDivIds: null, // set to an array to only load certain <div>s, example - ["divId1", "divId2"]
          noSlotReload: false, // set true to only load ad slots that have never been loaded 
      });
  });
})();`;

const LoadRelevantDigitalScript = () => {
  return (
    <>
      <Script src={relevantStaticScript}></Script>
      <Script
        id="relevant-digital-script"
        type="application/javascript"
        dangerouslySetInnerHTML={{
          __html: relevantDigitalPrebidScript
        }}
      ></Script>
    </>
  );
};

export { LoadRelevantDigitalScript };
