export type RGBA = string;
export type TextStyle =
  | "h0"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "h7"
  | "feature1"
  | "feature2"
  | "quote"
  | "button"
  | "button2"
  | "overline"
  | "underline"
  | "underline2"
  | "body"
  | "lead"
  | "lead2"
  | "body3"
  | "subheading";

export type ColorPalette = {
  background_primary?: RGBA;
  background_secondary?: RGBA;
  primary?: RGBA;
  text_primary?: RGBA;
  text_secondary?: RGBA;
  divider?: RGBA;
  surface_primary?: RGBA;
  surface_secondary?: RGBA;
  inactive?: RGBA;
  highlight?: RGBA;
};

export function rgbaToCss(value?: RGBA): React.CSSProperties | null {
  if (!value) {
    return null;
  }
  const hexValues = value.match(/[^#]{2}/g) || [];
  const [r, g, b, a] = hexValues.map(hex => parseInt(hex, 16));
  const rgba = `rgba(${r},${g},${b},${(a / 255).toPrecision(2)})`;
  return rgba as React.CSSProperties;
}

export type Decoration = {
  color_palette?: {
    light: ColorPalette;
    dark: ColorPalette;
  };
  text_styles?: {
    h0?: TextStyle;
    h1?: TextStyle;
    h2?: TextStyle;
    h3?: TextStyle;
    h4?: TextStyle;
    h5?: TextStyle;
    h6?: TextStyle;
    h7?: TextStyle;
    feature1?: TextStyle;
    feature2?: TextStyle;
    quote?: TextStyle;
    button?: TextStyle;
    button2?: TextStyle;
    overline?: TextStyle;
    underline?: TextStyle;
    underline2?: TextStyle;
    body?: TextStyle;
    lead?: TextStyle;
    lead2?: TextStyle;
    body3?: TextStyle;
    subheading?: TextStyle;
  };
};
