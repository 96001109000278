import type { Resource, ExternalImage, Url, Image } from "../../client";

import { imageSrc, sizeNameToSizeValues } from "../../lib/image";
import { getThumbnailUrl } from "../../components/Article/Resources/UrlResource/VideoResource";

type SharedImageType = {
  url: string;
  size?: { width: number; height: number };
};

const shareDimensions = sizeNameToSizeValues.share;

export function isImage(resource: Resource): resource is ExternalImage {
  return resource.type === "Image";
}

export function isVideo(resource: Resource): resource is Url {
  return (
    resource.type === "Url" &&
    resource.data?.source?.toLowerCase() === "youtube"
  );
}
export function isStockImage(resource: Resource): resource is ExternalImage {
  return resource.type === "ExternalImage" && resource.subtype === "stock";
}

export function getStockSharedImage(image: ExternalImage): SharedImageType {
  return {
    url: `${image.url}&w=${shareDimensions.w}&h=${shareDimensions.h}`,
    size: { width: shareDimensions.w, height: shareDimensions.h }
  };
}

export function isExternalImage(resource: Resource): resource is ExternalImage {
  return resource.type === "ExternalImage" && resource.subtype === "stock";
}

export function getYoutubeThumbnail(resource: Url) {
  return {
    url: getThumbnailUrl(resource, "large"),
    size: { width: 480, height: 360 }
    // may change in future when other formats than 16:9 become more popular
  };
}

export function getSharedImage(image: Image): SharedImageType {
  return {
    url: imageSrc(image, "share", true),
    size: { width: shareDimensions.w, height: shareDimensions.h }
  };
}

export function getExternalImage(image: ExternalImage): SharedImageType {
  return image;
}

type ResourceToSharedImageCond = [
  (resource: Resource) => boolean,
  (resource: any) => SharedImageType // It is the Resource which is quialifed in first function
];
export function getShareImage(resource: Resource): SharedImageType | undefined {
  const conds: ResourceToSharedImageCond[] = [
    [isVideo, getYoutubeThumbnail],
    [isStockImage, getStockSharedImage],
    [isImage, getSharedImage]
  ];

  const [maybeImage] = conds
    .map(([qualifier, generator]) => {
      if (qualifier(resource)) {
        return generator(resource);
      }
      return undefined;
    })
    .filter(s => s);
  return maybeImage;
}
