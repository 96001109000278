/**
 * Omni Article Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import type { Block } from "./block";
import type { Inscription } from "./inscription";

export type BlockParagraph = {
  block_type: "BlockParagraph" | "paragraph";
  text: Inscription;
};
export function isBlockParagraph(block: Block): block is BlockParagraph {
  return (
    block.block_type === "BlockParagraph" || block.block_type === "paragraph"
  );
}
