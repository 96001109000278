import React from "react";
import Head from "next/head";

import type { Article } from "../../client";

import { getShareImage } from "./sharedImage";
import { getConfig } from "../../config";
import { extractArticleDescription } from "../../helpers/extractArticleDescription";

type OpenGraphTagsPropsType = { article?: Article };

const defaultFBDescription =
  "Få alla mediers nyheter och perspektiv samlat på ett ställe, bevaka ämnen och skapa din egen nyhetsmix.";

function OpenGraphImageTags({ article }: OpenGraphTagsPropsType) {
  const shareImage =
    article?.main_resource && getShareImage(article?.main_resource);
  if (!shareImage) {
    return null;
  }
  const { url, size } = shareImage;
  return (
    <>
      <meta property="og:image" key="og:image" content={url} />
      {size ? (
        <meta
          property="og:image:width"
          key="og:image:width"
          content={size.width.toString()}
        />
      ) : null}

      {size ? (
        <meta
          property="og:image:height"
          key="og:image:height"
          content={size.height.toString()}
        />
      ) : null}
    </>
  );
}

const OpenGraphTags = ({ article }: OpenGraphTagsPropsType) => {
  const { translation } = getConfig();
  const title = article?.title?.value || translation.title;
  const description = extractArticleDescription(article);
  return (
    <Head>
      <meta
        property="og:site_name"
        key="og:site_name"
        content={translation?.application_name}
      />
      <meta property="og:locale" key="og:locale" content="sv_SE" />
      <meta
        property="og:type"
        key="og:type"
        content={`${article ? "article" : "website"}`}
      />
      <meta property="og:title" key="og:title" content={title} />
      <meta
        property="og:description"
        key="og:description"
        content={`${description || defaultFBDescription}`}
      />
      {OpenGraphImageTags({ article })}
    </Head>
  );
};
export { OpenGraphTags };
