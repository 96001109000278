import type { Image } from "../client";

export const imageServer =
  process.env.NEXT_PUBLIC_IMAGE_SERVER || "https://gfx.omni.se";

export const watermarkServer =
  process.env.NEXT_PUBLIC_WATERMARK_IMAGE_SERVER ||
  "https://watermark-gfx.omni.se";

type IdFields = Pick<Image, "image_asset" | "source_id" | "watermarks">;

export type Size =
  | "large"
  | "small"
  | "expanded"
  | "sidebar"
  | "1x1"
  | "1x2"
  | "share";

type ImageProperties = {
  h: number;
  w: number;
  tight?: boolean;
};

type SizeMapping = { [key in Size]: ImageProperties };

export const sizeNameToSizeValues: SizeMapping = {
  large: { w: 1000, h: 500, tight: false },
  expanded: { w: 1200, h: 600 },
  small: { w: 180, h: 180, tight: true },
  sidebar: { w: 80, h: 80, tight: true },
  "1x1": { w: 640, h: 640, tight: true },
  "1x2": { w: 640, h: 320, tight: true },
  share: { w: 1200, h: 628, tight: false }
};

export const imageSrc = (
  image: IdFields,
  size: Size,
  useFallback?: boolean
): string => {
  const { w, h, tight: maybeTight = null } = sizeNameToSizeValues[size];
  const tightQuery = maybeTight !== null ? `&tight=${maybeTight}` : "";
  const id = useFallback ? image.image_asset.id : image.source_id;
  if (size === "share" && image.watermarks) {
    const watermark = image.watermarks.filter(w => w.format === "2x1")[0].url;
    return `${watermarkServer}/images/${id}?h=${h}${tightQuery}&w=${w}&watermark=${watermark}`;
  } else {
    return `${imageServer}/images/${id}?h=${h}${tightQuery}&w=${w}`;
  }
};

export const getImageDimensions = (imageUrl: string) => {
  const matches = /.*h=([^&]*).*w=([^&]*).*/g.exec(imageUrl);
  if (matches?.length === 3) {
    return {
      width: parseInt(matches[2]),
      height: parseInt(matches[1])
    };
  }

  return {
    width: 0,
    height: 0
  };
};
