import { getConfig } from "../config";

export const isSafeUrl = (stringUrl: string) => {
  const { NEXT_PUBLIC_ALLOWED_REDIRECT_DOMAINS } = getConfig();
  let domain: string;

  try {
    domain = new URL(stringUrl).host;
  } catch (error) {
    console.error(`Incorrect URL (${stringUrl})`, error);
    return false;
  }

  // If any of the allowed domains matches
  return NEXT_PUBLIC_ALLOWED_REDIRECT_DOMAINS.some(allowedDomain => {
    const regex = new RegExp(`\\b${allowedDomain}\\b$`);
    return !!domain.match(regex);
  });
};

export const safeRedirect = (
  stringUrl: string,
  onError?: (error: string) => void
) => {
  if (!stringUrl) return onError?.("Url is empty");
  if (!isSafeUrl(stringUrl)) return onError?.("This url is not allowed");
  window.location.href = stringUrl;
};
