import type { Article } from "../../../client";

import { createTrackedArticle, getOrigin } from "../articleTracking";

export function trackArticleViewEvent(
  pulseSdk: any,
  {
    NEXT_PUBLIC_DOMAIN,
    NEXT_PUBLIC_PREMIUM_NEWSROOM,
    article,
    route,
    isLoggedIn,
    user_id,
    referrer
  }: {
    NEXT_PUBLIC_DOMAIN: string;
    NEXT_PUBLIC_PREMIUM_NEWSROOM: boolean;
    article: Article;
    route: string;
    isLoggedIn: boolean;
    user_id: string;
    referrer: string;
  }
) {
  pulseSdk?.newPageView();
  pulseSdk?.track("trackerEvent", {
    schema: "http://schema.schibsted.com/events/tracker-event.json/252.json",
    type: "View",
    object: createTrackedArticle(
      article,
      route,
      NEXT_PUBLIC_DOMAIN,
      NEXT_PUBLIC_PREMIUM_NEWSROOM,
      isLoggedIn,
      user_id,
      undefined,
      referrer
    ),
    ...getOrigin(NEXT_PUBLIC_DOMAIN, route, referrer)
  });
}
