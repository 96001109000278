import type { ArticlesResponse, Category } from "../models";

import { ApiClient } from "../../apiClient";
import { usermixToQueryObject } from "../../helpers";
import { PremiumStateError } from "../../PremiumStateError";

const enableMer = process.env.NEXT_PUBLIC_MER === "true";

export type SortType = "newsmix" | "latest" | "bookmarks";
export type SearchArticlesParams = {
  sort?: SortType;
  offset?: number;
  articleOffset?: number;
  limit?: number;
  category?: Category;
  topics?: string[];
  usermix?: { [key: string]: number };
  signal?: AbortSignal;
  exchangeToken?: string;
};

export async function fetchArticles({
  sort = "newsmix",
  offset = 0,
  limit = 10,
  articleOffset = 0,
  category,
  topics,
  usermix = {},
  signal,
  exchangeToken
}: SearchArticlesParams): Promise<ArticlesResponse> {
  const query = {
    offset,
    articleOffset,
    limit,
    sort,
    category: typeof category === "object" ? category?.category_id : category,
    topics,
    feature_flag: enableMer ? "mer" : undefined,
    ...usermixToQueryObject(usermix)
  };

  const makeReq = new ApiClient("GET", "content");
  try {
    return {
      ...(await makeReq.send("/articles", { query, exchangeToken, signal })),
      searchParams: query
    };
  } catch (e) {
    if (e instanceof PremiumStateError && e.payload) {
      // TODO The reason that we get 401 or 403 and content is that Fastly calls /verify-premium and that status code is used. 401 = non-valid exchange_token, 403 = non-premium exchange_token
      const resolvedJson = await e.payload.json();
      return { ...resolvedJson, searchParams: query };
    }
    throw e;
  }
}
