import { ApiClient } from "../../apiClient";

export async function deleteTopicSubscription({
  topicId,
  signal
}: {
  topicId: string;
  signal?: AbortSignal;
}) {
  const settingsClient = new ApiClient("DELETE", "settings");
  return settingsClient.send(`/subscriptions/${topicId}`, {
    mode: "cors",
    credentials: "include",
    signal
  });
}
