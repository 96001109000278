import type { TopicsUpdateBody } from "../models";

import { ApiClient } from "../../apiClient";

type UpdateSubscriptionInput = {
  topic_id: string;
  body: TopicsUpdateBody;
  signal?: AbortSignal;
};

export async function updateTopicSubscription({
  topic_id,
  body,
  signal
}: UpdateSubscriptionInput) {
  const settingsClient = new ApiClient("PUT", "settings");

  return settingsClient.send(`/subscriptions/${topic_id}`, {
    mode: "cors",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
    signal
  });
}
