export function trackLoginPosterEvent(
  pulseSdk: any,
  {
    NEXT_PUBLIC_PULSE_CLIENT_ID
  }: {
    NEXT_PUBLIC_PULSE_CLIENT_ID: string;
  }
) {
  pulseSdk?.newPageView();
  pulseSdk?.track("trackerEvent", {
    schema: "http://schema.schibsted.com/events/tracker-event.json/252.json",
    "@type": "View",
    object: {
      loginMethod: "embedded-schibsted-account",
      name: "SubscriptionLogin",
      "@id": `sdrn:${NEXT_PUBLIC_PULSE_CLIENT_ID}:loginposter:subscriptions`,
      "@type": "LoginPoster"
    }
  });
}
