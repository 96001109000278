import React, { useState, useEffect, useCallback, createContext } from "react";
import { useRouter } from "next/router";

import type { ReactNode } from "react";

import { getConfig } from "../../config";
import { useGlobalStore } from "../GlobalStateContainer/store";

const lockOnRoute = (route: string) => {
  switch (route) {
    case "/article":
      return true;
    case "/subscriptions":
      return true;
    default:
      return false;
  }
};

type LockFlags = {
  isLocked: boolean;
  canMinimize: boolean;
  show: boolean;
  noActivePopup: boolean;
};
type LockStates = "INACTIVE" | "ACTIVE" | "LOCKED_WITH_POPUP" | "LOCKED";

const stateToFlags: Record<LockStates, LockFlags> = {
  INACTIVE: {
    isLocked: false,
    canMinimize: false,
    show: false,
    noActivePopup: false
  },
  ACTIVE: {
    isLocked: false,
    canMinimize: false,
    show: true,
    noActivePopup: false
  },
  LOCKED_WITH_POPUP: {
    isLocked: true,
    canMinimize: false,
    show: true,
    noActivePopup: true
  },
  LOCKED: {
    isLocked: true,
    canMinimize: true,
    show: true,
    noActivePopup: false
  }
};

export const PremiumLockContainer = ({ children }: { children: ReactNode }) => {
  const { isLoggedIn, subscriptionsInfo } = useGlobalStore();
  const { NEXT_PUBLIC_PREMIUM_NEWSROOM } = getConfig();

  const [lockState, setLockState] = useState<LockStates>(
    NEXT_PUBLIC_PREMIUM_NEWSROOM && !subscriptionsInfo?.isPremium
      ? "ACTIVE"
      : "INACTIVE"
  );

  const router = useRouter();

  useEffect(() => {
    if (
      NEXT_PUBLIC_PREMIUM_NEWSROOM &&
      !subscriptionsInfo?.isPremium &&
      lockOnRoute(router.route)
    ) {
      setLockState(isLoggedIn ? "LOCKED_WITH_POPUP" : "LOCKED");
    } else if (isLoggedIn && subscriptionsInfo?.isPremium) {
      setLockState("INACTIVE");
    }
  }, [
    router.route,
    isLoggedIn,
    subscriptionsInfo?.isPremium,
    NEXT_PUBLIC_PREMIUM_NEWSROOM
  ]);

  const checkLock = (activateLock = true) => {
    if (!NEXT_PUBLIC_PREMIUM_NEWSROOM || subscriptionsInfo?.isPremium) {
      setLockState("INACTIVE");
      return false;
    }

    if (
      activateLock &&
      NEXT_PUBLIC_PREMIUM_NEWSROOM &&
      !subscriptionsInfo?.isPremium
    ) {
      setLockState(isLoggedIn ? "LOCKED_WITH_POPUP" : "LOCKED");
    }

    return NEXT_PUBLIC_PREMIUM_NEWSROOM && !subscriptionsInfo?.isPremium;
  };

  const lockScreen = (isLocked: boolean) => {
    if (!NEXT_PUBLIC_PREMIUM_NEWSROOM || subscriptionsInfo?.isPremium) {
      setLockState("INACTIVE");
      return;
    }

    setLockState(isLocked ? "LOCKED" : "ACTIVE");
  };

  const closeNoActivePopup = useCallback(
    () => setLockState("LOCKED"),
    [setLockState]
  );

  return (
    <PremiumLockContext.Provider
      value={{
        lockState,
        checkLock,
        lockScreen,
        closeNoActivePopup,
        ...stateToFlags[lockState]
      }}
    >
      {children}
    </PremiumLockContext.Provider>
  );
};

/** @INFO - Experimental inconsistency change
    Context file has been removed to check if it's more benefitial
    and heatlhy to keep contexts related things closer together
    rather than splitted across the file structure
*/

type PremiumLockContextType = {
  isLocked: boolean;
  show: boolean;
  canMinimize: boolean;
  lockState: LockStates;
  noActivePopup: boolean;
  checkLock: (activateLock?: boolean) => boolean;
  lockScreen: (isLocked: boolean) => void;
  closeNoActivePopup: () => void;
};

export const PremiumLockContext = createContext<PremiumLockContextType>({
  isLocked: false,
  lockState: "INACTIVE",
  show: false,
  canMinimize: true,
  noActivePopup: false,
  checkLock: (activateLock?: boolean) => false,
  lockScreen: (isLocked: boolean) => {},
  closeNoActivePopup: () => {}
});

PremiumLockContext.displayName = "PremiumLockContext";
