import type { Article } from "../../../client";
import type { TargetArticleType } from "../../../contexts/TrackingContext";

import {
  createTargetArticle,
  createEvergreenObject,
  getTargetUrl
} from "../articleTracking";

export function trackEvergreenClickEvent(
  pulseSdk: any,
  {
    NEXT_PUBLIC_DOMAIN,
    NEXT_PUBLIC_PULSE_CLIENT_ID,
    originArticle,
    targetArticle
  }: {
    NEXT_PUBLIC_DOMAIN: string;
    NEXT_PUBLIC_MER: boolean;
    NEXT_PUBLIC_PULSE_CLIENT_ID: string;
    originArticle: Article;
    targetArticle: TargetArticleType;
  }
) {
  pulseSdk?.newPageView();
  pulseSdk?.track("trackerEvent", {
    type: "Engagement",
    schema: "http://schema.schibsted.com/events/engagement-event.json/335.json",
    action: "Click",
    object: createEvergreenObject(
      originArticle,
      targetArticle,
      NEXT_PUBLIC_PULSE_CLIENT_ID
    ),
    target: createTargetArticle(
      {
        article_id: targetArticle.article_id,
        title: { value: targetArticle.title },
        is_premium: true
      },
      getTargetUrl(originArticle, NEXT_PUBLIC_DOMAIN)
    )
  });
}
