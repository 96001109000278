import type { ConfigType } from "./ConfigType";

import { getConfigForNewsRoom } from "./index";

export function getConfig(): ConfigType {
  return {
    NEXT_PUBLIC_NEWSROOM: process.env.NEXT_PUBLIC_NEWSROOM || "",
    NEXT_PUBLIC_DEEPLINK_APPID: process.env.DEEPLINK_APPID || "",
    NEXT_PUBLIC_DOMAIN: process.env.NEXT_PUBLIC_DOMAIN || "",
    NEXT_PUBLIC_LBMP_API_BASE: process.env.NEXT_PUBLIC_LBMP_API_BASE || "",
    NEXT_PUBLIC_LBMP_PUBLISHER: process.env.NEXT_PUBLIC_LBMP_PUBLISHER || "",
    NEXT_PUBLIC_PREMIUM_NEWSROOM:
      (process.env.NEXT_PUBLIC_PREMIUM_NEWSROOM || "false").toLowerCase() ===
      "true",
    NEXT_PUBLIC_HAS_AD_FREE_SUBSCRIPTION:
      (
        process.env.NEXT_PUBLIC_HAS_AD_FREE_SUBSCRIPTION || "false"
      ).toLowerCase() === "true",
    NEXT_PUBLIC_PSI_APP_ID: process.env.NEXT_PUBLIC_PSI_APP_ID || "",
    NEXT_PUBLIC_PRIVACY_MANAGER_ID:
      process.env.NEXT_PUBLIC_PRIVACY_MANAGER_ID || "",
    NEXT_PUBLIC_PREBID_CONFIG_ID:
      process.env.NEXT_PUBLIC_PREBID_CONFIG_ID || "",
    NEXT_PUBLIC_PREBID_TAG_ID: process.env.NEXT_PUBLIC_PREBID_TAG_ID || "",
    NEXT_PUBLIC_TCF_PROPERTY_ID: process.env.NEXT_PUBLIC_TCF_PROPERTY_ID || "",
    NEXT_PUBLIC_TCF_PROPERTY_HREF:
      (!!process.env.NEXT_PUBLIC_TCF_PROPERTY_HREF &&
        process.env.NEXT_PUBLIC_TCF_PROPERTY_HREF === "true") ||
      false,
    NEXT_PUBLIC_PULSE_CLIENT_ID: process.env.NEXT_PUBLIC_PULSE_CLIENT_ID || "",
    NEXT_PUBLIC_PULSE_DEPLOY_STAGE:
      process.env.NEXT_PUBLIC_PULSE_DEPLOY_STAGE || "dev",
    NEXT_PUBLIC_SETTINGS_API_BASE:
      process.env.NEXT_PUBLIC_SETTINGS_API_BASE || "",
    NEXT_PUBLIC_SPID_CLIENT_ID: process.env.NEXT_PUBLIC_SPID_CLIENT_ID || "",
    NEXT_PUBLIC_SPID_ENV: process.env.NEXT_PUBLIC_SPID_ENV || "",
    NEXT_PUBLIC_SPID_REDIRECT_URL:
      process.env.NEXT_PUBLIC_SPID_REDIRECT_URL || "",
    NEXT_PUBLIC_SPID_COOKIE_DOMAIN:
      process.env.NEXT_PUBLIC_SPID_COOKIE_DOMAIN || "",
    NODE_ENV: process.env.NODE_ENV || "development",
    NEXT_PUBLIC_PULSE_PRODUCT_TAG:
      process.env.NEXT_PUBLIC_PULSE_PRODUCT_TAG ||
      process.env.NEXT_PUBLIC_PULSE_CLIENT_ID ||
      "",
    NEXT_PUBLIC_MER: process.env.NEXT_PUBLIC_MER === "true" || false,
    NEXT_PUBLIC_ALLOWED_HOSTS_TO_CRAWL: process.env
      .NEXT_PUBLIC_ALLOWED_HOSTS_TO_CRAWL
      ? process.env.NEXT_PUBLIC_ALLOWED_HOSTS_TO_CRAWL.split(",").map(origin =>
          origin.trim()
        )
      : [
          "web-omni-ekonomi.omni-pro-1.omni.news",
          "web-omni.omni-pro-1.omni.news"
        ],
    NEXT_PUBLIC_ALLOWED_REDIRECT_DOMAINS:
      process.env.NEXT_PUBLIC_ALLOWED_REDIRECT_DOMAINS?.split(/\s*,\s*/) || [],
    NEXT_PUBLIC_ITERATE_KEY: process.env.NEXT_PUBLIC_ITERATE_KEY || "",
    ...getConfigForNewsRoom(process.env.NEXT_PUBLIC_NEWSROOM || "")
  };
}
