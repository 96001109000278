import type { Article } from "../../../client";

import { getSessionStart } from "../pulseSession";
import { createTrackedViewedAd } from "../articleTracking";

export function trackAdInFeed(
  pulseSdk: any,
  {
    NEXT_PUBLIC_PULSE_CLIENT_ID,
    NEXT_PUBLIC_DOMAIN,
    pathname,
    article,
    position
  }: {
    NEXT_PUBLIC_PULSE_CLIENT_ID: string;
    NEXT_PUBLIC_DOMAIN: string;
    pathname: string;
    article: Article;
    position: number;
  }
) {
  pulseSdk?.track("trackerEvent", {
    type: "View",
    sessionStart: getSessionStart(),
    ...createTrackedViewedAd(
      NEXT_PUBLIC_PULSE_CLIENT_ID,
      NEXT_PUBLIC_DOMAIN,
      pathname,
      article,
      position
    )
  });
}
