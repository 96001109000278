import type { Article, Topic } from "../../../client";

import {
  createTrackedArticle,
  updateArticleOpenTime
} from "../articleTracking";

const slugRegex = /(\/t)(\/.+)(\/)/;

export type TrackArticleExpandEventType = {
  NEXT_PUBLIC_PULSE_CLIENT_ID: string;
  NEXT_PUBLIC_DOMAIN: string;
  NEXT_PUBLIC_PREMIUM_NEWSROOM: boolean;
  article: Article;
  route: string;
  loggedIn: boolean;
  user_id: string;
  topic?: Topic;
  asPath: string;
};

export function trackArticleExpandEvent(
  pulseSdk: any,
  {
    NEXT_PUBLIC_PULSE_CLIENT_ID,
    NEXT_PUBLIC_DOMAIN,
    NEXT_PUBLIC_PREMIUM_NEWSROOM,
    article,
    route,
    loggedIn,
    user_id,
    topic,
    asPath
  }: TrackArticleExpandEventType
) {
  updateArticleOpenTime(article.article_id);
  pulseSdk?.newPageView();
  pulseSdk?.track("trackerEvent", {
    schema: "http://schema.schibsted.com/events/tracker-event.json/252.json",
    type: "View",
    object: createTrackedArticle(
      article,
      route,
      NEXT_PUBLIC_DOMAIN,
      NEXT_PUBLIC_PREMIUM_NEWSROOM,
      loggedIn,
      user_id,
      topic
    ),
    origin: {
      url: new URL(asPath, `https://${NEXT_PUBLIC_DOMAIN}`)
        .toString()
        .replace(slugRegex, "$1$3")
    }
  });
}
