import type { ArticleResponse } from "../models";

import { ApiClient } from "../../apiClient";

export type FetchSingleArticleProps = {
  articleId: string;
  exchangeToken?: string;
  signal?: AbortSignal;
};

export async function fetchSingleArticle({
  articleId,
  exchangeToken,
  signal
}: FetchSingleArticleProps): Promise<ArticleResponse> {
  const makeReq = new ApiClient("GET", "content");

  return makeReq.send(`/articles/${articleId}`, {
    signal,
    exchangeToken
  });
}
