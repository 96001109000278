import React from "react";

import { LoadApnTags } from "./ApnTag";
import { useCookiePermission } from "../../hooks/cookiePermission/useCookiePermission";
import { twitterWidgetInit } from "./TwitterWidgetScript";
import { getConfig } from "../../config";
import { OpenGraphTags } from "../Meta/OpenGraphTags";
import { TwitterTags } from "../Meta/TwitterTags";
import { AppleTouchIcons } from "../Meta/AppleTouchIcons";
import { iterateScript } from "./IterateScript";
import { LoadRelevantDigitalScript } from "./relevantDigitalScript";

const Scripts = () => {
  const advertisingConsent = useCookiePermission("CMP:advertising");
  const analyticsConsent = useCookiePermission("CMP:analytics");
  const { NEXT_PUBLIC_ITERATE_KEY } = getConfig();

  return (
    <>
      <LoadApnTags />
      <LoadRelevantDigitalScript />
      <OpenGraphTags />
      <TwitterTags />
      <AppleTouchIcons />
      {advertisingConsent && twitterWidgetInit()}
      {analyticsConsent && NEXT_PUBLIC_ITERATE_KEY && iterateScript()}
    </>
  );
};

export { Scripts };
