import { sessionsDelete } from "../../../../client";
import { UseLocalStorage } from "../../../../hooks/UseLocalStorage";
import { getIdentityInstance } from "../utils/getIdentityInstance";

const logout = async (): Promise<void> => {
  try {
    const identityInstance = getIdentityInstance();

    if (identityInstance) {
      try {
        await sessionsDelete();
      } catch (error) {
        console.error("Error when deleting sessions during logout: ", error);
      }

      document.cookie =
        "usermix=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      document.cookie =
        "exchange_token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      UseLocalStorage().remove("omni-user");

      identityInstance.logout(window.location.href);
    }
  } catch (error) {
    console.error("Error when logout: ", error);
  }
};

export { logout };
