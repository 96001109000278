import type { CategoriesResponse } from "../models";

import { ApiClient } from "../../apiClient";

type ShowCategories = "all" | "public";
type FeatureFlag = "dividers";
export type GetCategoriesParams = {
  show?: ShowCategories;
  featureFlag?: FeatureFlag;
  signal?: AbortSignal;
};
export async function fetchCategories({
  show,
  featureFlag,
  signal
}: GetCategoriesParams): Promise<CategoriesResponse> {
  const query = {
    show,
    feature_flag: featureFlag
  };

  const makeReq = new ApiClient("GET", "content");
  return makeReq.send("/categories", { query, signal });
}
