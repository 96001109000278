import type { NextRouter } from "next/router";
import type { Article, Category } from "../../../client";

import { trackViewEvent } from "./trackViewEvent";
import { getPulseSdk, getTopic } from "../index";

export function trackViewFeed({
  isLoggedIn,
  router,
  NEXT_PUBLIC_PULSE_CLIENT_ID,
  NEXT_PUBLIC_DOMAIN,
  article,
  category
}: {
  isLoggedIn: boolean;
  router: NextRouter;
  NEXT_PUBLIC_PULSE_CLIENT_ID: string;
  NEXT_PUBLIC_DOMAIN: string;
  article?: Article;
  category?: Category;
}) {
  const { asPath, pathname } = router;

  const data = {
    NEXT_PUBLIC_PULSE_CLIENT_ID,
    NEXT_PUBLIC_DOMAIN,
    asPath,
    isLoggedIn,
    pathname
  };

  switch (pathname) {
    case "/topic":
    case "/topic_w_o_slug":
      const topic = getTopic(router, article);

      getPulseSdk(tracker => {
        trackViewEvent(tracker, {
          ...data,
          topic
        });
      });
      break;
    case "/category":
      getPulseSdk(tracker => {
        trackViewEvent(tracker, {
          ...data,
          category
        });
      });
      break;
    default:
      getPulseSdk(tracker => {
        trackViewEvent(tracker, {
          ...data
        });
      });
  }
}
