import React from "react";
import Script from "next/script";

import { getConfig } from "../../config";

export const iterateScript = () => {
  const { NEXT_PUBLIC_ITERATE_KEY } = getConfig();
  return (
    <Script
      id="iterate-js-script"
      type="application/javascript"
      dangerouslySetInnerHTML={{
        __html: `window.iterateSettings = {
            apiKey: '${NEXT_PUBLIC_ITERATE_KEY}'
          };
          
          (function(i,t,e,r,a){if(t.getElementById(r)){return}
          i.IterateObjectName=a;var z=function(){z.c(arguments)};z.q=[];
          z.c=function(args){z.q.push(args)};i[a]=z;
          function l() {var js,fjs=t.getElementsByTagName(e)[0];js=t.createElement(e);js.id=r;js.async=1;js.src="https://platform.iteratehq.com/loader.js";fjs.parentNode.insertBefore(js,fjs)};
          if(t.readyState==="complete") {l();} else if(i.attachEvent) {i.attachEvent('onload', l);} else{i.addEventListener('load', l, false);}
          }(window, document,'script','iterate-js','Iterate'));`
      }}
    ></Script>
  );
};
