import type { Consents } from "@spt-tracking/pulse-sdk/dist/cjs/builders";

export type TCFCategories =
  | "CMP:marketing"
  | "CMP:analytics"
  | "CMP:personalisation"
  | "CMP:advertising";

export type TCFPermission = "1" | "0";

export interface TCF {
  getPermission: (
    category: TCFCategories,
    callback: (value: TCFPermission) => void
  ) => void;
  subscribe: (
    category: TCFCategories,
    callback: (value: TCFPermission) => void
  ) => () => Promise<void>;
  showPrivacyManager: () => void;
  getConsentedToAllSync: () => boolean;
  isConsentedToAll(callback: (value: boolean) => void): () => void;
  getCachedOrDefaultConsentsForPulse: () => Consents;
}

export function getTCF(): Promise<TCF> {
  return new Promise(resolve => {
    setTimeout(() => {
      const cookieConsent = window._tcf_;
      if (cookieConsent) {
        resolve(cookieConsent);
      } else {
        resolve(getTCF());
      }
    }, 100);
  });
}

export async function isConsentToAll() {
  const tcf = await getTCF();

  return tcf.getConsentedToAllSync();
}

export function getTCFSync(cb: (sdk: TCF) => void): void {
  getTCF().then(cb);
}

export function transformPermissionValue(value: string): boolean {
  return Boolean(Number(value));
}
