import NextLink from "next/link";
import React, { useContext } from "react";

import type { AnchorHTMLAttributes, DetailedHTMLProps, ReactNode } from "react";
import type { Article, Category } from "../../client";

import { PremiumLockContext } from "../../containers/PremiumLockContainer";
import { TrackingContext } from "../../contexts/TrackingContext";
import { omnify } from "../../lib/slugification";
import { UseLocalStorage } from "../../hooks/UseLocalStorage";

type aElementProps = Omit<
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
  "ref"
> & { ref?: React.Ref<HTMLAnchorElement> };

export function urlToArticle(article: Article) {
  const slug =
    article.meta?.promotion_content?.slug || omnify(article.title.value);
  return `/${slug}/a/${article.article_id}`;
}

//eslint-disable-next-line react/display-name
export const LinkToArticle = React.forwardRef(
  (
    {
      article,
      children,
      ...rest
    }: {
      article: Article;
      children: ReactNode;
    } & aElementProps,
    ref: React.Ref<HTMLAnchorElement>
  ) => {
    const { checkLock } = useContext(PremiumLockContext);
    const { setReferrer } = useContext(TrackingContext);

    return (
      <NextLink
        ref={ref}
        href={urlToArticle(article)}
        onClick={e => {
          if (checkLock(false)) {
            e.preventDefault();
          }

          UseLocalStorage().set("clickedArticle", article);

          setReferrer(window?.location?.href);
        }}
        {...rest}
      >
        {children}
      </NextLink>
    );
  }
);

export function LinkToArticleWithOnlyId({
  articleId,
  children,
  ...rest
}: { articleId: string; children: ReactNode } & aElementProps) {
  const { checkLock } = useContext(PremiumLockContext);
  return (
    <NextLink
      href={`/a/${articleId}`}
      {...rest}
      onClick={e => {
        if (checkLock(false)) {
          e.preventDefault();
        }
      }}
    >
      {children}
    </NextLink>
  );
}

export function LinkToTopic({
  topic: { title, topic_id },
  children,
  ...rest
}: {
  topic: { title: string; topic_id: string };
  children: ReactNode;
} & aElementProps) {
  return (
    <NextLink
      data-isclickable="true"
      href={`/t/${omnify(title)}/${topic_id}`}
      {...rest}
    >
      {children}
    </NextLink>
  );
}

export function LinkToIndex({ children, ...rest }: aElementProps) {
  return (
    <NextLink href="/" {...rest}>
      {children}
    </NextLink>
  );
}

export function LinkToLatest({ children, ...rest }: aElementProps) {
  return (
    <NextLink href="/senaste" {...rest}>
      {children}
    </NextLink>
  );
}

export function LinkToSubscriptions({ children, ...rest }: aElementProps) {
  return (
    <NextLink href="/bevakningar" {...rest}>
      {children}
    </NextLink>
  );
}

export function LinkToBookmarks({ children, ...rest }: aElementProps) {
  return (
    <NextLink href="/bokmarken" {...rest}>
      {children}
    </NextLink>
  );
}
export function LinkToCategory({
  category: { slug },
  children,
  ...rest
}: { category: Category } & aElementProps) {
  if (!slug) {
    return null;
  }
  return (
    <NextLink href={`/${slug}`} {...rest}>
      {children}
    </NextLink>
  );
}
