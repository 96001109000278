import type {
  Article,
  ArticleLayout,
  ArticleTeaserLayout
} from "../../../../client";

import { nonExpandableFormat } from "../../helpers";

const tinyLayouts = ["tiny"];
const smallLayouts = [
  "Small",
  "SponsoredSmall",
  "PassionSmall",
  "PassionSponsored"
];

const isTinyLayout = (layout: string) => {
  return tinyLayouts.indexOf(layout) > -1;
};

const isSmallLayout = (layout: string) => {
  return smallLayouts.indexOf(layout) > -1;
};

// TODO refactor to use inherited TeaserContext
const getLayoutToDisplaySize = (
  layout: ArticleTeaserLayout | ArticleLayout | undefined
) => {
  if (!layout) {
    return "large";
  }

  if (isSmallLayout(layout.toString())) return "small";
  if (isTinyLayout(layout.toString())) return "tiny";
  return "large";
};

export const getArticleDisplaySize = (article: Article) => {
  return getLayoutToDisplaySize(
    article._full || nonExpandableFormat(article)
      ? article.article_layout
      : article.teaser_layout
  );
};
