import React from "react";
import Image from "next/image";

import type { ReactNode } from "react";
import type {
  Article,
  Resource,
  Url,
  ModelMap,
  ExternalImage,
  Image as ImageType,
  ArticleFormat,
  Group
} from "../../client";
import type { Style } from "../../client/ContentApi/models/style";
import type { ResourceGroup } from "../../client/ContentApi/models/resourceGroup";
import type { HorizontalPadding } from "../../client/ContentApi/models/horizontalPadding";

import { LinkToArticle } from "../Link";
import { getImageSizes } from "../../helpers";

const sponsorHeaderSizes = getImageSizes({
  web: "100px",
  tablet: "15vw",
  mobile: "30vw"
});

export const isBreaking = (article: Article) => {
  return article.teaser_layout === "Flash" || article.breaking;
};

export const isLongread = (article: Article) =>
  article?.article_layout === "Longread";

export const isInfoCard = (article: Article) =>
  article?.teaser_layout === "Fishstick";

export const nonExpandableFormat = (article: Article) =>
  isLongread(article) ||
  isBreaking(article) ||
  isInfoCard(article) ||
  isOmniMerFormat(article.format);

export const isSupertagOrTopic = (target: HTMLElement) =>
  target?.className === "resource--vignette-supertag" ||
  target?.className === "resource--vignette-topic ";

export const isExternalLink = (target: HTMLElement) =>
  target?.className.startsWith &&
  target?.className.startsWith("resource--link");

export const isOmniMerFormat = (format: ArticleFormat) => format !== "regular";

export const isRegularSponsored = ({ format, article_layout }: Article) =>
  format === "regular" && article_layout === "Sponsored";

export const isSponsored = ({ article_layout }: Article) =>
  article_layout === "Sponsored";

export const isSimpleUrl = (style: Style) => style === "Simple";

export const getFormatPostfix = (format?: ArticleFormat) =>
  !format ? "" : `--${format}`;

export const getStylePostfix = (style?: Style) =>
  !style ? "" : `--${style.toLocaleLowerCase()}`;

export const getGroupedStyle = (group?: ResourceGroup) =>
  group
    ? `resource--grouped resource--grouped-${group.position.toLowerCase()}`
    : "";

export const getHorizontalPaddingClass = (padding?: HorizontalPadding) => {
  const base = `resource--image--horizontalpadding`;
  const classes = [];

  const { small_device, large_device } = padding || {
    small_device: false,
    large_device: false
  };

  if (small_device) classes.push(base + "--small");
  if (large_device) classes.push(base + "--large");
  return classes.join(" ");
};

const generateLogoImgForSponsorHeader = (article: Article) => {
  if (!article.sponsor_header || !article.sponsor_header.logo) {
    return null;
  }

  return (
    <Image
      src={article.sponsor_header.logo}
      alt={article.sponsor_header.label}
      className="sponsor-header-logo"
      sizes={sponsorHeaderSizes}
      width={0}
      height={0}
    />
  );
};

export const generateSponsorHeader = (article: Article) => {
  if (!article.sponsor_header) {
    return null;
  }

  const imgLogo = generateLogoImgForSponsorHeader(article);

  return (
    <div className="sponsor-header">
      <span className="sponsor-header-label">
        {article.sponsor_header.label}
        {imgLogo}
      </span>
    </div>
  );
};

export const clickedOnVideoThumbnail = (
  e: React.SyntheticEvent,
  article: Article
) => {
  if (article.main_resource && article.main_resource.type === "Url") {
    const element = e.target as HTMLElement;
    const classes = element.parentElement?.classList || new DOMTokenList();

    return (
      classes.contains("resource-playbtn") ||
      classes.contains("resource--image--video")
    );
  }
  return false;
};

export const clickedOnStar = (e: React.SyntheticEvent) => {
  const element = e.target as HTMLElement;
  return element.classList[0] && element.classList[0] === "icon";
};

export const clickedOnImage = (e: React.SyntheticEvent) => {
  const element = e.target as HTMLElement;
  return element.tagName === "IMG";
};

export const scrollToTopOfArticle = (el: Element) => {
  const header = document.getElementsByClassName("header")[0];
  const rect = el.getBoundingClientRect();

  window.scrollTo(
    0,
    Math.round(rect.y + (window.scrollY - header.clientHeight))
  );
};

export const shouldRenderBanner = (
  resourceType: string,
  index: number,
  resources: Resource[]
) => {
  const firstUrlIndex = resources.findIndex(resource => {
    return resource.type === "Url";
  });

  return (
    index === firstUrlIndex ||
    (resourceType === "Subheading" && index + 1 === firstUrlIndex)
  );
};

export function isCanvasAd(article: Article) {
  const behaviour = getCanvasAdBehaviour(article);
  return behaviour !== undefined;
}

export function getCanvasAdBehaviour(article: Article) {
  return (article?.teaser_behaviour || []).find(
    b => b.action === "open" && b.interaction === "open-webview"
  );
}

export const brandStudioTrackingPixel = (article: Article) => {
  return (
    <>
      {article.tracking_urls?.map(tracking_url => (
        <Image
          className="resource resource--trackingtag"
          src={tracking_url}
          alt=""
          key={tracking_url}
          sizes={sponsorHeaderSizes}
          width={0}
          height={0}
        />
      ))}
    </>
  );
};

const getVideoId = (resource: Url) => {
  return resource?.data?.custom_properties?.video_id;
};

const getImageId = (resource: ImageType) => {
  return resource?.image_asset.id;
};

const isSameMap = (map1: ModelMap, map2: ModelMap) => {
  return (
    map1.location.lat === map2?.location?.lat &&
    map1.location.lon === map2?.location?.lon
  );
};

export const isMainResource = (
  mainResource: Resource | undefined,
  resource: Resource
) => {
  if (mainResource?.type !== resource.type) {
    return false;
  }

  switch (mainResource.type) {
    case "Image":
      return getImageId(mainResource) === getImageId(resource as ImageType);
    case "ModelMap":
      return isSameMap(mainResource, resource as ModelMap);
    case "ExternalImage":
      return mainResource.url === (resource as ExternalImage)?.url;
    case "Url":
      if (mainResource.subtype === "youtube") {
        return getVideoId(mainResource) === getVideoId(resource as Url);
      }

      return false;
    default:
      return false;
  }
};

export function getPosition(
  article: Article,
  articles: Group[],
  isDynamicBox: boolean = false
): number {
  return articles
    .reduce((acc, val) => acc.concat(val), [])
    .filter(feedItem => feedItem.type === "Article" || feedItem.type === "Box")
    .reduce<(Article & { isDynamicBox: boolean })[]>((articles, feedItem) => {
      switch (feedItem.type) {
        case "Article":
          return articles.concat({
            ...(feedItem as Article),
            isDynamicBox: false
          });
        case "Box":
          return articles.concat(
            feedItem.articles.reduce<(Article & { isDynamicBox: boolean })[]>(
              (articles, boxItem) => {
                if (boxItem.type === "Article") {
                  return articles.concat({
                    ...boxItem,
                    isDynamicBox: true
                  });
                }

                return articles;
              },
              []
            )
          );
        default:
          return articles;
      }
    }, [])
    .findIndex(
      feedArticle =>
        feedArticle.article_id === article.article_id &&
        feedArticle.isDynamicBox === isDynamicBox
    );
}

export const GetArticleLinkType = ({
  article,
  children,
  linkRef
}: {
  article: Article;
  children: ReactNode;
  linkRef?: React.Ref<HTMLAnchorElement>;
}) => {
  if (isCanvasAd(article)) {
    return (
      <a
        href={getCanvasAdBehaviour(article)?.target}
        rel="noreferrer"
        target="_blank"
        ref={linkRef}
      >
        {children}
      </a>
    );
  }

  return (
    <LinkToArticle
      article={article}
      className="article-link"
      target="_self"
      ref={linkRef}
    >
      {children}
    </LinkToArticle>
  );
};
