import { createContext } from "react";

import type { NextRouter } from "next/router";
import type {
  Article,
  BoxHeading,
  TitleVignette,
  SalesposterView,
  Url,
  Category
} from "../client";
import type { UsermixSettings } from "../containers/TrackingContainer/events/trackUsermixEvents";

export type TargetArticleType = {
  article_id: string;
  title: string;
  vignette?: TitleVignette;
};

export const TrackingContext = createContext({
  shareButton: (
    target: "facebook" | "twitter" | "email" | "linkedin",
    article: Article
  ) => {},
  playVideo: (videoId: string, article: Article) => {},
  articleExpand: (article: Article, position: number) => {},
  trackViewedAdInFeed: (article: Article, position: number) => {},
  articleCollapse: (article: Article) => {},
  leaveArticle: (article: Article) => {},
  openExternal: (url: Url, article: Article, index?: number) => {},
  openInternalLink: (article: Article) => {},
  articleTopic: (topicId: string, article: Article) => {},
  fav: (topicId: string, article: Article) => {},
  unfav: (topicId: string, article: Article) => {},
  usermixOpened: (usermix: UsermixSettings) => {},
  usermixClosed: (usermix: UsermixSettings) => {},
  setSalesPosterReferrerArticle: (
    salesPosterReferrerArticle: Article | undefined
  ) => {},
  setReferrer: (referrer: string) => {},
  loginPoster: () => {},
  teaserClick: (
    article: Article,
    position: number,
    dynamicBoxName: string
  ) => {},
  teaserImpression: (
    article: Article,
    position: number,
    dynamicBoxName: string
  ) => {},
  themeBoxImpression: (resource: BoxHeading, position: number) => {},
  salesPoster: ({ plan }: { plan?: SalesposterView }) => {},
  bookmarkAdded: (article: Article) => {},
  inArticleEvergreenClick: (
    originArticle: Article,
    targetArticle: TargetArticleType
  ) => {},
  buySubscription: (
    subscriptionPlan: string,
    subscriptionType: string,
    adFree: boolean
  ) => {},
  featureImpression: (position: "first" | "last", article?: Article) => {},
  ctaFeaturesClick: (feature: string) => {},
  salesPosterLoginClick: () => {},
  salesPosterReadMore: () => {},
  trackViewFeed: ({
    isLoggedIn,
    router,
    NEXT_PUBLIC_PULSE_CLIENT_ID,
    NEXT_PUBLIC_DOMAIN,
    article,
    category
  }: {
    isLoggedIn: boolean;
    router: NextRouter;
    NEXT_PUBLIC_PULSE_CLIENT_ID: string;
    NEXT_PUBLIC_DOMAIN: string;
    article?: Article;
    category?: Category;
  }) => {},
  trackViewArticle: ({
    article,
    route,
    isLoggedIn,
    referrer,
    user_id,
    NEXT_PUBLIC_PREMIUM_NEWSROOM,
    NEXT_PUBLIC_DOMAIN
  }: {
    article: Article;
    route: string;
    isLoggedIn: boolean;
    referrer: string;
    user_id: string;
    NEXT_PUBLIC_PREMIUM_NEWSROOM: boolean;
    NEXT_PUBLIC_DOMAIN: string;
  }) => {},
  trackViewSubscriptions: ({
    isLoggedIn,
    router,
    NEXT_PUBLIC_PULSE_CLIENT_ID,
    NEXT_PUBLIC_DOMAIN
  }: {
    isLoggedIn: boolean;
    router: NextRouter;
    NEXT_PUBLIC_PULSE_CLIENT_ID: string;
    NEXT_PUBLIC_DOMAIN: string;
  }) => {},
  referrer: "",
  salesPosterReferrerArticle: undefined as Article | undefined,
  isPulseReady: false
});
