import { UseLocalStorage } from "../../hooks/UseLocalStorage";

type session = {
  sessionStart: number;
  lastUpdate: number;
  articleOpenTimes: { [key: string]: number };
};

const getUpdatedSessionIfNeeded = (currentSession: session | null): session => {
  const currentTime = new Date().getTime();
  if (
    !currentSession ||
    currentTime - currentSession.lastUpdate > 10 * 60 * 1000
  ) {
    return {
      sessionStart: currentTime,
      lastUpdate: currentTime,
      articleOpenTimes: {}
    };
  }
  return {
    ...currentSession,
    lastUpdate: currentTime
  };
};

export const updateSession = (updatedSession: session) => {
  UseLocalStorage().set("pulseSession", updatedSession);
};

export const getSession = () => {
  const currentSession = UseLocalStorage().get(
    "pulseSession"
  ) as session | null;

  const updatedSession = getUpdatedSessionIfNeeded(currentSession);
  updateSession(updatedSession);
  return updatedSession;
};

export const getSessionStart = () => {
  return getSession().sessionStart;
};
