import React, { useEffect } from "react";
import Script from "next/script";

import { getApnTag } from "../../components/Ad/utils/ads/functions";
import { getConfig } from "../../config";

const LoadApnTags = () => {
  const { features } = getConfig();
  const ADS_ENABLED = features.some(feature => feature === "ADS_ENABLED");

  useEffect(() => {
    getApnTag().then((apntag: any) => {
      if (apntag?.setEndpoint) {
        apntag.setEndpoint("ib.adnxs.com", true);
      }
    });
  }, []);

  if (!ADS_ENABLED) {
    return null;
  }

  return <Script type="text/javascript" key="appnexus" src="/js/apntag.js" />;
};

export { LoadApnTags };
