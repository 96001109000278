export function getTwitterWidget(): Promise<any> {
  return new Promise((resolve, reject) => {
    if (typeof window === "undefined") {
      reject();
    }

    if ((window as any)?.twttr?.init) {
      resolve((window as any)?.twttr);
    }

    setTimeout(() => {
      resolve(getTwitterWidget());
    }, 200);
  });
}
