import React from "react";
import Head from "next/head";

import type { Article, Resource } from "../../client";

import { getShareImage } from "./sharedImage";
import { getConfig } from "../../config";
import { extractArticleDescription } from "../../helpers/extractArticleDescription";

function TwitterImage({ resource }: { resource?: Resource }) {
  const shareImage = resource && getShareImage(resource);

  if (!shareImage) {
    return null;
  }
  const { url, size } = shareImage;
  return (
    <>
      <meta name="twitter:image" content={url} />
      {size ? (
        <meta name="twitter:image:height" content={`${size.height}`} />
      ) : null}

      {size ? (
        <meta name="twitter:image:width" content={`${size.width}`} />
      ) : null}
    </>
  );
}

const TwitterTags = ({ article }: { article?: Article }) => {
  const {
    translation: { twitter, application_name, title: defaultTitle }
  } = getConfig();

  const title = article?.title?.value || defaultTitle || "";
  const description = extractArticleDescription(article);
  return (
    <Head>
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:description"
        content={description || twitter.description}
      />
      <meta name="twitter:title" content={`${application_name} - ${title}`} />
      <meta name="twitter:site" content={twitter.site} />
      {article?.main_resource && (
        <TwitterImage resource={article?.main_resource} />
      )}

      <meta name="twitter:creator" content={twitter.site} />
    </Head>
  );
};

export { TwitterTags };
