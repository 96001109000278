import React from "react";

interface Props {
  text?: string;
  show?: boolean;
}

export const Caption = ({ text, show = false }: Props) => {
  if (!text || !show) return <></>;
  return <div className="resource--image-caption">{text}</div>;
};
