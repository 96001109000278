import type { NextRouter } from "next/router";

import { trackViewEvent } from "./trackViewEvent";
import { getPulseSdk } from "../index";
import { trackLoginPosterEvent } from "./trackLoginPosterEvent";

export function trackViewSubscriptions({
  isLoggedIn,
  router,
  NEXT_PUBLIC_PULSE_CLIENT_ID,
  NEXT_PUBLIC_DOMAIN
}: {
  isLoggedIn: boolean;
  router: NextRouter;
  NEXT_PUBLIC_PULSE_CLIENT_ID: string;
  NEXT_PUBLIC_DOMAIN: string;
}) {
  const { asPath, pathname } = router;

  if (isLoggedIn) {
    getPulseSdk(tracker => {
      trackViewEvent(tracker, {
        NEXT_PUBLIC_PULSE_CLIENT_ID,
        NEXT_PUBLIC_DOMAIN,
        asPath,
        isLoggedIn,
        pathname
      });
    });
  } else {
    getPulseSdk(tracker => {
      trackLoginPosterEvent(tracker, { NEXT_PUBLIC_PULSE_CLIENT_ID });
    });
  }
}
