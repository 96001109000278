import type { StateCreator } from "zustand";
import type { UserSlice } from "./types";
import type { Permissions } from "../../../server/lbmpService";

import { initialUserState } from "./initialState";

const createUserSlice: StateCreator<UserSlice> = (set, get) => ({
  ...initialUserState,
  setIsLoggedIn: isLoggedIn => {
    set({ isLoggedIn });
  },
  setUserInfo: userInfo => {
    set({ userInfo });
  },
  setExchangeToken: exchangeToken => {
    document.cookie = `exchange_token=${exchangeToken}; path=/`;

    set({ exchangeToken });
  },
  setSubscriptionsInfo: subscriptionsInfo => {
    set({ subscriptionsInfo });
  },
  setDataFetchingResults: dataFetchingResults => {
    set({ dataFetchingResults });
  },
  setPersonalizedContent: ({ usermix, bookmarks, subscribedTopics }) => {
    const {
      usermix: previousUsermix = {},
      bookmarks: previousBookmarks = [],
      subscribedTopics: previousSubscribedTopics = []
    } = get().personalizedContent;

    document.cookie = `usermix=${JSON.stringify({
      usermix: {
        ...get().personalizedContent.usermix,
        ...usermix
      }
    })}; path=/`;

    set({
      personalizedContent: {
        usermix: {
          ...previousUsermix,
          ...usermix
        },
        bookmarks: bookmarks?.length ? [...bookmarks] : [...previousBookmarks],
        subscribedTopics: subscribedTopics?.length
          ? [...subscribedTopics]
          : [...previousSubscribedTopics]
      }
    });
  },
  setUserPermissions: (permissions: Permissions) => {
    set({ permissions });
  },
  setFullUserState: fullState => {
    set({ ...fullState });
  }
});

export { createUserSlice };
