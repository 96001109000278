const breakpoints = {
  mobile: "(max-width: 667px)",
  tablet: "(max-width: 1023px)"
};

type GetImageSizesParams = {
  web: string;
  tablet?: string;
  mobile?: string;
};

type GetImageSizes = (params: GetImageSizesParams) => string;

const getImageSizes: GetImageSizes = ({ web, tablet, mobile }) => {
  if (web && tablet && mobile) {
    return `${breakpoints["mobile"]} ${mobile}, ${breakpoints["tablet"]} ${tablet}, ${web}`;
  }

  if (web && tablet) {
    return `${breakpoints["tablet"]} ${tablet}, ${web}`;
  }

  if (web && mobile) {
    return `${breakpoints["mobile"]} ${mobile}, ${web}`;
  }

  return web;
};

export { getImageSizes };
