import sharedVarsJSON from "../../shared-variables.json";

export const allowedPreBannerClasses = [
  "hidden-xl",
  "hidden-xs",
  "hidden-sm",
  "hidden-md",
  "hidden-lg"
];

export const triggerPoints = [
  {
    placement: "articlePanorama",
    offset: 100
  },
  {
    placement: "newsfeed",
    offset: 200
  },
  {
    placement: "fullscreen",
    offset: 300
  }
];

export const sharedVars = JSON.parse(JSON.stringify(sharedVarsJSON));

export const layoutNames: { [key: string]: string } = {
  "screen-xs-min": "mobile",
  "screen-sm-min": "mobile",
  "screen-md-min": "desktop",
  "screen-lg-min": "desktop",
  "screen-xl-min": "desktop"
};

export const specialCharacters = /["'=!+#*~;^()<>[\]@:?%]/g;

export const isConfiguredAndLoaded = new Set();
