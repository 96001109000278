import { ApiClient } from "../../apiClient";

const enableMer = process.env.NEXT_PUBLIC_MER;

export type SearchParams = {
  queryString: string;
  offset?: number;
  limit?: number;
  signal?: AbortSignal;
  exchangeToken?: string;
};

export function search({
  queryString,
  offset = 0,
  limit = 10,
  signal,
  exchangeToken
}: SearchParams) {
  const query = {
    query: queryString,
    offset,
    limit,
    feature_flag: enableMer ? "mer" : undefined
  };
  const makeReq = new ApiClient("GET", "content");

  return makeReq.send("/search", { query, signal, exchangeToken });
}
