/**
 * Omni Article Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import type { EnrichmentData } from "./enrichmentData";
import type { Resource } from "./resource";
import type { Inscription } from "./inscription";
import type { Decoration } from "./decoration";
import type { Style } from "./style";
import type { HorizontalPadding } from "./horizontalPadding";

interface UrlMetadata {
  canonical_url?: string;
  limited?: boolean;
  paywall?: "Hard" | "Soft";
  omni_key?: boolean;
}

export type Url = {
  /**
   * Type of Resource
   */
  type: "Url";
  /**
   * Subtype defines subject of usage the resource
   */
  sponsored?: boolean;
  subtype?: UrlSubtype;
  url: string;
  title?: Inscription;
  label?: string;
  source: string;
  data?: EnrichmentData;
  show_paywall_popup?: boolean;
  paywall?: "Hard" | "Soft";
  omni_key?: boolean;
  metadata: UrlMetadata;
  source_suffix?: {
    value: string;
    decoration?: Decoration;
  };

  /**
   * Omni Mer
   */
  style: Style;
  show_caption?: boolean;
  horizontal_padding?: HorizontalPadding;
};

export type UrlSubtype = "stock" | "lead" | "youtube";

export function isUrl(resource: Resource): resource is Url {
  return resource.type === "Url";
}
