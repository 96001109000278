import type { SettingsPutBody } from "../models";

import { ApiClient } from "../../apiClient";

export function updateSettingsField(
  field: string,
  newFields: SettingsPutBody,
  signal?: AbortSignal
) {
  const settingsClient = new ApiClient("PUT", "settings");

  return settingsClient.send(`/settings/${field}`, {
    mode: "cors",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(newFields),
    signal
  });
}
