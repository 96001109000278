import type { Article, Topic } from "../../../client";

import { createTargetArticle, getTargetUrl } from "../articleTracking";
import {
  getId,
  getSize,
  getUrl,
  getType,
  mapPathnameToView
} from "./trackViewEvent";

const getObject = (
  NEXT_PUBLIC_PULSE_CLIENT_ID: string,
  NEXT_PUBLIC_DOMAIN: string,
  pathname: string,
  asPath: string,
  article: Article,
  position: number,
  dynamicBoxName: string,
  topic?: Topic
) => {
  const view = mapPathnameToView(pathname);
  const type = getType(view);
  const sponsor = article.sponsor
    ? {
        is_sponsored: true,
        sponsor: article.sponsor,
        ...(article.teaser_layout === "Fishstick" && {
          context: "InfoCardPosition"
        }),
        ...(article.meta?.in_cluster && { context: "NativeCluster" })
      }
    : {};

  return {
    name: article.title.value,
    "@id": `sdrn:${NEXT_PUBLIC_PULSE_CLIENT_ID}:${type.toLowerCase()}:teaser:element:${
      article.article_id
    }`,
    "@type": "UIElement",
    elementType: "Teaser",
    position: position.toString(),
    page: {
      "@id": topic
        ? getId(NEXT_PUBLIC_PULSE_CLIENT_ID, type, view, topic)
        : getId(NEXT_PUBLIC_PULSE_CLIENT_ID, type, view),
      url: topic
        ? getUrl(view, NEXT_PUBLIC_DOMAIN, asPath, topic)
        : getUrl(view, NEXT_PUBLIC_DOMAIN, asPath),
      "@type": type
    },
    "spt:custom": {
      size: getSize(article, view),
      story: article.story?.title || "",
      vignette: article.title?.vignette?.value || "",
      ...(dynamicBoxName !== "" && { context: dynamicBoxName }),
      ...sponsor
    }
  };
};

export function trackTeaserClickEvent(
  pulseSdk: any,
  {
    NEXT_PUBLIC_PULSE_CLIENT_ID,
    NEXT_PUBLIC_DOMAIN,
    article,
    pathname,
    position,
    asPath,
    dynamicBoxName,
    topic
  }: {
    NEXT_PUBLIC_PULSE_CLIENT_ID: string;
    NEXT_PUBLIC_DOMAIN: string;
    article: Article;
    pathname: string;
    asPath: string;
    position: number;
    dynamicBoxName: string;
    topic?: Topic;
  }
) {
  pulseSdk?.newPageView();
  pulseSdk?.track("trackerEvent", {
    type: "Engagement",
    schema: "http://schema.schibsted.com/events/engagement-event.json/187.json",
    action: "Click",
    object: getObject(
      NEXT_PUBLIC_PULSE_CLIENT_ID,
      NEXT_PUBLIC_DOMAIN,
      pathname,
      asPath,
      article,
      position + 1,
      dynamicBoxName,
      topic
    ),
    target: createTargetArticle(
      article,
      getTargetUrl(article, NEXT_PUBLIC_DOMAIN)
    )
  });
}
