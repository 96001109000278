/**
 * Omni Article Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import type { BylineAuthors } from "./bylineAuthors";
import type { Resource } from "./resource";

export type Byline = {
  /**
   * Type of Resource
   */
  type: "Byline";
  /**
   * Subtype defines subject of usage the resource
   */
  subtype?: BylineSubtype;
  updated?: string;
  published: string;
  authors: Array<BylineAuthors>;
  /**
   * Provided if the newsroom does not want to use personal emails or if personal email is missing
   */
  fallback_email: string;
};

export type BylineSubtype = "stock" | "lead" | "youtube";

export function isByline(resource: Resource): resource is Byline {
  return resource.type === "Byline";
}
