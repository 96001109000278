import type { Bookmark } from "../models/bookmark";
import type { SubscribedTopic } from "../models";

import { ApiClient } from "../../apiClient";

export type Settings = {
  settings: {
    usermix: { [key: string]: number };
    local_topic: { id: string };
    organization_topic: any;
  };
  subscriptions: SubscribedTopic[];
  user_id: string;
  bookmarks: Bookmark[];
};

export function getSettings(signal?: AbortSignal): Promise<Settings> {
  const settingsClient = new ApiClient("GET", "settings");

  return settingsClient.send("/all", {
    mode: "cors",
    credentials: "include",
    signal
  });
}
