import type { Article } from "./article";
import type { Decoration } from "./decoration";
import type { Component } from "./component";

type Header = {
  type: string;
  value: string;
  decoration?: Decoration;
};

type Footer = {
  type: "ReadMoreButton";
  value?: string;
  link: {
    type: "Category" | "story";
    category_id?: string;
    topic_id?: string;
    slug?: string;
    title?: string;
  };
  decoration: Decoration;
};

export type Box = {
  type: "Box";
  name: string;
  header: Header[];
  articles: Article[];
  footer: Footer[];
};

export function isGroupWithBox(group: Array<Component>): group is Array<Box> {
  return group[0] && group[0].type === "Box";
}
