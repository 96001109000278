import type { Article } from "../../../client";

export function trackPlayVideoEvent(
  pulseSdk: any,
  {
    NEXT_PUBLIC_PULSE_CLIENT_ID,
    article,
    videoId
  }: { NEXT_PUBLIC_PULSE_CLIENT_ID: string; article: Article; videoId: string }
) {
  const articleId = article.article_id;
  const articleSponsor = article.sponsor;
  const articleTitle = article.title.value;

  pulseSdk?.track("trackerEvent", {
    type: "Engagement",
    schema: "http://schema.schibsted.com/events/engagement-event.json/187.json",
    action: "Click",
    object: {
      id: videoId,
      type: "Video",
      "spt:custom": {
        article_id: articleId,
        article_title: articleTitle,
        sponsor: articleSponsor || null,
        is_sponsored: !!articleSponsor
      }
    }
  });
}
