import type { Article } from "../client";

export function extractArticleDescription(
  article?: Partial<Article>
): null | string {
  const paragraphs = article?.main_text?.paragraphs;

  if (!paragraphs || !Array.isArray(paragraphs) || paragraphs.length < 1) {
    return null;
  }

  const description = paragraphs[0]?.text?.value;

  if (description) {
    return description;
  }

  return null;
}
