let usermixOpeningSnapshot: string;

export type UsermixSettings = {
  [key: string]: number;
};

export function trackUsermixOpenedEvent(
  pulseSdk: any,
  {
    NEXT_PUBLIC_PULSE_CLIENT_ID,
    usermix
  }: { NEXT_PUBLIC_PULSE_CLIENT_ID: string; usermix: UsermixSettings }
) {
  usermixOpeningSnapshot = JSON.stringify(usermix);
}

export function trackUsermixClosedEvent(
  pulseSdk: any,
  {
    NEXT_PUBLIC_PULSE_CLIENT_ID,
    usermix
  }: { NEXT_PUBLIC_PULSE_CLIENT_ID: string; usermix: UsermixSettings }
) {
  const currentUsermixSnapshot = JSON.stringify(usermix);

  if (!usermixOpeningSnapshot) {
    return;
  }

  pulseSdk?.track("trackerEvent", {
    type: "Engagement",
    schema: "http://schema.schibsted.com/events/engagement-event.json/177.json",
    action: "Click",
    object: {
      "@id": `sdrn:${NEXT_PUBLIC_PULSE_CLIENT_ID}:page:newsmix`,
      name: "Newsmix",
      type: "Page",
      "spt:custom": {
        newsmix_modified: usermixOpeningSnapshot !== currentUsermixSnapshot
      }
    }
  });

  usermixOpeningSnapshot = "";
}
