import { ApiClient } from "../../apiClient";

export type ExchangeToken = {
  exchange_token: string;
};

export function getExchangeToken(signal?: AbortSignal): Promise<ExchangeToken> {
  const settingsClient = new ApiClient("GET", "settings");

  return settingsClient.send("/exchange-token", {
    mode: "cors",
    credentials: "include",
    signal
  });
}
