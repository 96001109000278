import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";

import type { NextRouter } from "next/router";
import type { ReactNode } from "react";
import type Tracker from "@spt-tracking/pulse-sdk";
import type {
  Article,
  Topic,
  SalesposterView,
  Url,
  BoxHeading
} from "../../client";
import type { TargetArticleType } from "../../contexts/TrackingContext";
import type { TrackArticleExpandEventType } from "./events/trackArticleExpandEvent";
import type { UsermixSettings } from "./events/trackUsermixEvents";

import { TrackingContext } from "../../contexts/TrackingContext";
import { trackAddBookmarkEvent } from "./events/trackAddBookmarkEvent";
import { trackArticleCollapseEvent } from "./events/trackArticleCollapseEvent";
import { trackArticleExpandEvent } from "./events/trackArticleExpandEvent";
import { trackArticleTopicEvent } from "./events/trackArticleTopicEvent";
import { trackEvergreenClickEvent } from "./events/trackEvergreenClickEvent";
import { trackFavEvent } from "./events/trackFavEvent";
import { trackLoginPosterEvent } from "./events/trackLoginPosterEvent";
import { trackOpenExternalEvent } from "./events/trackOpenExternalEvent";
import { trackOpenInternalLinkEvent } from "./events/trackOpenInternalLinkEvent";
import { trackPlayVideoEvent } from "./events/trackPlayVideoEvent";
import {
  trackPremiumSalesPosterEvent,
  trackBuySubscriptionEvent,
  trackFeatureEvent,
  trackFeatureCTAEvent,
  tracksalesPosterLoginEvent,
  tracksalesPosterReadMoreEvent
} from "./events/trackPremiumSalesPosterEvents";
import { trackShareEvent } from "./events/trackShareEvent";
import { trackTeaserClickEvent } from "./events/trackTeaserClickEvent";
import { trackTeaserImpression } from "./events/trackTeaserImpression";
import { trackThemeBoxImpression } from "./events/trackThemeBoxImpression";
import { trackUnfavEvent } from "./events/trackUnfavEvent";
import {
  trackUsermixClosedEvent,
  trackUsermixOpenedEvent
} from "./events/trackUsermixEvents";
import { trackAdInFeed } from "./events/trackViewedAdInFeed";
import { trackArticleLeaveEvent } from "./events/trackArticleLeaveEvent";
import { trackViewFeed } from "./events/trackViewFeed";
import { trackViewArticle } from "./events/trackViewArticle";
import { trackViewSubscriptions } from "./events/trackViewSubscriptions";
import { getConfig } from "../../config";
import { useGlobalStore } from "../GlobalStateContainer/store";

export function getPulseSdk(cb: (tracker: Tracker) => void): any | undefined {
  if (typeof window === "undefined" || !window.pulse) {
    return undefined;
  }
  return window.pulse(cb);
}

export function getTopic(
  router: NextRouter,
  article: Article | undefined
): Topic | undefined {
  const topicId = router.query.topicId;

  if (topicId === article?.story?.topic_id) {
    return article?.story;
  } else if (topicId === article?.story_vignette?.supertag?.topic_id) {
    return article?.story_vignette.supertag;
  } else {
    return article?.tags?.find((tag: Topic) => tag.topic_id === topicId);
  }
}

export function TrackingContainer({ children }: { children: ReactNode }) {
  const [isPulseReady, setPulseReady] = useState(false);
  const [salesPosterReferrerArticle, setSalesPosterReferrerArticle] =
    useState<Article>();
  const [referrer, setReferrer] = useState<string>("");
  const {
    NEXT_PUBLIC_PULSE_CLIENT_ID,
    NEXT_PUBLIC_DOMAIN,
    NEXT_PUBLIC_PREMIUM_NEWSROOM,
    NEXT_PUBLIC_MER
  } = getConfig();
  const { isLoggedIn, userInfo, subscriptionsInfo } = useGlobalStore();
  const router = useRouter();

  const salesPoster = useCallback(
    ({
      plan
    }: {
      plan?: SalesposterView;
    } = {}) => {
      getPulseSdk(tracker => {
        trackPremiumSalesPosterEvent(tracker, {
          NEXT_PUBLIC_PULSE_CLIENT_ID,
          NEXT_PUBLIC_DOMAIN,
          article: salesPosterReferrerArticle,
          plan
        });
      });
    },
    [
      NEXT_PUBLIC_PULSE_CLIENT_ID,
      NEXT_PUBLIC_DOMAIN,
      salesPosterReferrerArticle
    ]
  );

  useEffect(() => {
    const actor: {
      id?: string | number;
      subscriptionName: string;
      realm?: string;
    } = {
      id: isLoggedIn ? userInfo?.id : undefined,
      subscriptionName: subscriptionsInfo?.isPremium
        ? "Premium"
        : "No SubscribedTopic",
      realm: "schibsted.com"
    };

    getPulseSdk(tracker => {
      tracker.update({ actor });
    });
    setPulseReady(true);
  }, [isLoggedIn, subscriptionsInfo?.isPremium, userInfo?.id]);

  useEffect(() => {
    if (!isPulseReady) {
      return;
    }

    if (NEXT_PUBLIC_PREMIUM_NEWSROOM && !subscriptionsInfo?.isPremium) {
      salesPoster();
    }
  }, [
    NEXT_PUBLIC_PREMIUM_NEWSROOM,
    isPulseReady,
    salesPoster,
    subscriptionsInfo?.isPremium
  ]);

  const shareButton = useCallback(
    (
      target: "facebook" | "twitter" | "email" | "linkedin",
      article: Article
    ) => {
      getPulseSdk(tracker => {
        trackShareEvent(tracker, {
          NEXT_PUBLIC_PULSE_CLIENT_ID,
          article,
          target
        });
      });
    },
    [NEXT_PUBLIC_PULSE_CLIENT_ID]
  );

  const playVideo = useCallback(
    (videoId: string, article: Article) => {
      getPulseSdk(tracker => {
        trackPlayVideoEvent(tracker, {
          NEXT_PUBLIC_PULSE_CLIENT_ID,
          article,
          videoId
        });
      });
    },
    [NEXT_PUBLIC_PULSE_CLIENT_ID]
  );

  const trackViewedAdInFeed = useCallback(
    (article: Article, position: number) => {
      const pathname = router.pathname;

      getPulseSdk(tracker => {
        trackAdInFeed(tracker, {
          NEXT_PUBLIC_PULSE_CLIENT_ID,
          NEXT_PUBLIC_DOMAIN,
          pathname,
          article,
          position
        });
      });
    },
    [NEXT_PUBLIC_PULSE_CLIENT_ID, NEXT_PUBLIC_DOMAIN]
  );

  const articleExpand = useCallback(
    (article: Article) => {
      const { route } = router;
      const asPath = router.asPath;

      const query: TrackArticleExpandEventType = {
        NEXT_PUBLIC_PULSE_CLIENT_ID,
        NEXT_PUBLIC_DOMAIN,
        NEXT_PUBLIC_PREMIUM_NEWSROOM,
        article,
        route,
        loggedIn: isLoggedIn,
        user_id: userInfo?.id,
        asPath
      };

      if (route === "/topic" || route === "/topic_w_o_slug") {
        query.topic = getTopic(router, article);
      }

      getPulseSdk(tracker => {
        trackArticleExpandEvent(tracker, query);
      });
    },
    [
      NEXT_PUBLIC_PULSE_CLIENT_ID,
      router,
      NEXT_PUBLIC_DOMAIN,
      NEXT_PUBLIC_PREMIUM_NEWSROOM,
      isLoggedIn,
      userInfo?.id
    ]
  );

  const articleCollapse = useCallback(
    (article: Article) => {
      const { route } = router;
      getPulseSdk(tracker => {
        trackArticleCollapseEvent(tracker, {
          NEXT_PUBLIC_PULSE_CLIENT_ID,
          NEXT_PUBLIC_DOMAIN,
          NEXT_PUBLIC_PREMIUM_NEWSROOM,
          article,
          route,
          loggedIn: isLoggedIn,
          user_id: userInfo?.id
        });
      });
    },
    [
      NEXT_PUBLIC_PULSE_CLIENT_ID,
      router,
      isLoggedIn,
      userInfo?.id,
      NEXT_PUBLIC_DOMAIN,
      NEXT_PUBLIC_PREMIUM_NEWSROOM
    ]
  );

  const leaveArticle = useCallback(
    (article: Article) => {
      const { route } = router;
      getPulseSdk(tracker => {
        trackArticleLeaveEvent(tracker, {
          NEXT_PUBLIC_PULSE_CLIENT_ID,
          NEXT_PUBLIC_DOMAIN,
          NEXT_PUBLIC_PREMIUM_NEWSROOM,
          article,
          route,
          loggedIn: isLoggedIn,
          user_id: userInfo?.id
        });
      });
    },
    [
      NEXT_PUBLIC_PULSE_CLIENT_ID,
      router,
      isLoggedIn,
      userInfo?.id,
      NEXT_PUBLIC_DOMAIN,
      NEXT_PUBLIC_PREMIUM_NEWSROOM
    ]
  );

  const openExternal = useCallback(
    (url: Url, article: Article, index?: number) => {
      const { route } = router;
      getPulseSdk(tracker => {
        trackOpenExternalEvent(tracker, {
          NEXT_PUBLIC_PULSE_CLIENT_ID,
          article,
          url,
          route,
          index
        });
      });
    },
    [NEXT_PUBLIC_PULSE_CLIENT_ID, router]
  );

  const openInternalLink = useCallback(
    (article: Article) => {
      const { route } = router;
      getPulseSdk(tracker => {
        trackOpenInternalLinkEvent(tracker, {
          NEXT_PUBLIC_PULSE_CLIENT_ID,
          NEXT_PUBLIC_DOMAIN,
          NEXT_PUBLIC_PREMIUM_NEWSROOM,
          article,
          route,
          loggedIn: isLoggedIn,
          user_id: userInfo?.id
        });
      });
    },
    [
      NEXT_PUBLIC_PULSE_CLIENT_ID,
      router,
      NEXT_PUBLIC_DOMAIN,
      NEXT_PUBLIC_PREMIUM_NEWSROOM,
      isLoggedIn,
      userInfo?.id
    ]
  );

  const articleTopic = useCallback(
    (topicId: string, article: Article) => {
      getPulseSdk(tracker => {
        trackArticleTopicEvent(tracker, {
          NEXT_PUBLIC_PULSE_CLIENT_ID,
          article,
          topicId
        });
      });
    },
    [NEXT_PUBLIC_PULSE_CLIENT_ID]
  );

  const fav = useCallback(
    (topicId: string, article?: Article) => {
      if (!article) {
        return;
      }
      getPulseSdk(tracker => {
        trackFavEvent(tracker, {
          NEXT_PUBLIC_PULSE_CLIENT_ID,
          article,
          topicId
        });
      });
    },
    [NEXT_PUBLIC_PULSE_CLIENT_ID]
  );

  const unfav = useCallback(
    (topicId: string, article?: Article) => {
      if (!article) {
        return;
      }
      getPulseSdk(tracker => {
        trackUnfavEvent(tracker, {
          NEXT_PUBLIC_PULSE_CLIENT_ID,
          article,
          topicId
        });
      });
    },
    [NEXT_PUBLIC_PULSE_CLIENT_ID]
  );

  const bookmarkAdded = useCallback(
    (article: Article) => {
      const { route } = router;
      getPulseSdk(tracker => {
        trackAddBookmarkEvent(tracker, {
          NEXT_PUBLIC_PULSE_CLIENT_ID,
          NEXT_PUBLIC_DOMAIN,
          NEXT_PUBLIC_PREMIUM_NEWSROOM,
          article,
          route,
          loggedIn: isLoggedIn,
          user_id: userInfo?.id
        });
      });
    },
    [
      NEXT_PUBLIC_PULSE_CLIENT_ID,
      NEXT_PUBLIC_DOMAIN,
      router,
      NEXT_PUBLIC_PREMIUM_NEWSROOM,
      isLoggedIn,
      userInfo?.id
    ]
  );

  const usermixOpened = useCallback(
    (usermix: UsermixSettings) => {
      getPulseSdk(tracker => {
        trackUsermixOpenedEvent(tracker, {
          NEXT_PUBLIC_PULSE_CLIENT_ID,
          usermix
        });
      });
    },
    [NEXT_PUBLIC_PULSE_CLIENT_ID]
  );

  const usermixClosed = useCallback(
    (usermix: UsermixSettings) => {
      getPulseSdk(tracker => {
        trackUsermixClosedEvent(tracker, {
          NEXT_PUBLIC_PULSE_CLIENT_ID,
          usermix
        });
      });
    },
    [NEXT_PUBLIC_PULSE_CLIENT_ID]
  );

  const loginPoster = useCallback(() => {
    getPulseSdk(tracker => {
      trackLoginPosterEvent(tracker, { NEXT_PUBLIC_PULSE_CLIENT_ID });
    });
  }, [NEXT_PUBLIC_PULSE_CLIENT_ID]);

  const teaserClick = useCallback(
    (article: Article, position: number, dynamicBoxName: string) => {
      const pathname = router.pathname;
      const asPath = router.asPath;

      if (
        pathname === "/" ||
        pathname === "/latest" ||
        pathname === "/subscriptions" ||
        pathname === "/article"
      ) {
        getPulseSdk(tracker => {
          trackTeaserClickEvent(tracker, {
            NEXT_PUBLIC_PULSE_CLIENT_ID,
            NEXT_PUBLIC_DOMAIN,
            article,
            pathname,
            asPath,
            position,
            dynamicBoxName
          });
        });
      }
    },
    [
      router.pathname,
      router.asPath,
      NEXT_PUBLIC_PULSE_CLIENT_ID,
      NEXT_PUBLIC_DOMAIN
    ]
  );

  const buySubscription = useCallback(
    (subscriptionPlan: string, subscriptionType: string, adFree: boolean) => {
      getPulseSdk(tracker => {
        trackBuySubscriptionEvent(tracker, {
          NEXT_PUBLIC_PULSE_CLIENT_ID,
          subscriptionPlan,
          subscriptionType,
          adFree
        });
      });
    },
    [NEXT_PUBLIC_PULSE_CLIENT_ID]
  );

  const ctaFeaturesClick = useCallback(
    (feature: string) => {
      getPulseSdk(tracker => {
        trackFeatureCTAEvent(tracker, {
          NEXT_PUBLIC_PULSE_CLIENT_ID,
          feature
        });
      });
    },
    [NEXT_PUBLIC_PULSE_CLIENT_ID]
  );

  const salesPosterLoginClick = useCallback(() => {
    getPulseSdk(tracker => {
      tracksalesPosterLoginEvent(tracker, {
        NEXT_PUBLIC_PULSE_CLIENT_ID
      });
    });
  }, [NEXT_PUBLIC_PULSE_CLIENT_ID]);

  const salesPosterReadMore = useCallback(() => {
    getPulseSdk(tracker => {
      tracksalesPosterReadMoreEvent(tracker, {
        NEXT_PUBLIC_PULSE_CLIENT_ID
      });
    });
  }, [NEXT_PUBLIC_PULSE_CLIENT_ID]);

  const featureImpression = useCallback(
    (position: "first" | "last", article?: Article) => {
      getPulseSdk(tracker => {
        trackFeatureEvent(tracker, {
          NEXT_PUBLIC_PULSE_CLIENT_ID,
          article,
          position
        });
      });
    },
    [NEXT_PUBLIC_PULSE_CLIENT_ID]
  );

  const inArticleEvergreenClick = useCallback(
    (originArticle: Article, targetArticle: TargetArticleType) => {
      getPulseSdk(tracker => {
        trackEvergreenClickEvent(tracker, {
          NEXT_PUBLIC_DOMAIN,
          NEXT_PUBLIC_MER,
          NEXT_PUBLIC_PULSE_CLIENT_ID,
          originArticle,
          targetArticle
        });
      });
    },
    [NEXT_PUBLIC_DOMAIN, NEXT_PUBLIC_PULSE_CLIENT_ID, NEXT_PUBLIC_MER]
  );

  const themeBoxImpression = useCallback(
    (resource: BoxHeading, position: number) => {
      const pathname = router.pathname;
      const asPath = router.asPath;
      getPulseSdk(tracker => {
        trackThemeBoxImpression(tracker, {
          NEXT_PUBLIC_PULSE_CLIENT_ID,
          NEXT_PUBLIC_DOMAIN,
          resource,
          pathname,
          asPath,
          position,
          loggedIn: isLoggedIn,
          user_id: userInfo?.id
        });
      });
    },
    [
      NEXT_PUBLIC_DOMAIN,
      NEXT_PUBLIC_PULSE_CLIENT_ID,
      isLoggedIn,
      router.asPath,
      router.pathname,
      userInfo?.id
    ]
  );

  const teaserImpression = useCallback(
    (article: Article, position: number, dynamicBoxName: string) => {
      const pathname = router.pathname;
      const asPath = router.asPath;
      getPulseSdk(tracker => {
        trackTeaserImpression(tracker, {
          NEXT_PUBLIC_PULSE_CLIENT_ID,
          NEXT_PUBLIC_DOMAIN,
          article,
          pathname,
          asPath,
          position,
          loggedIn: isLoggedIn,
          user_id: userInfo?.id,
          dynamicBoxName
        });
      });
    },
    [
      NEXT_PUBLIC_DOMAIN,
      NEXT_PUBLIC_PULSE_CLIENT_ID,
      isLoggedIn,
      router.asPath,
      router.pathname,
      userInfo?.id
    ]
  );

  return (
    <TrackingContext.Provider
      value={{
        shareButton,
        playVideo,
        trackViewedAdInFeed,
        articleExpand,
        articleCollapse,
        leaveArticle,
        openExternal,
        openInternalLink,
        articleTopic,
        fav,
        unfav,
        usermixOpened,
        usermixClosed,
        setSalesPosterReferrerArticle,
        salesPosterReferrerArticle,
        referrer,
        setReferrer,
        loginPoster,
        teaserClick,
        salesPoster,
        bookmarkAdded,
        inArticleEvergreenClick,
        themeBoxImpression,
        teaserImpression,
        buySubscription,
        featureImpression,
        ctaFeaturesClick,
        salesPosterLoginClick,
        salesPosterReadMore,
        trackViewFeed,
        trackViewArticle,
        trackViewSubscriptions,
        isPulseReady
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
}
