import { useEffect, useState } from "react";

import type { TCFCategories } from "../../components/Ad/utils/cookieConsent";

import { getTCFSync } from "../../components/Ad/utils/cookieConsent";

export function useCookiePermission(category: TCFCategories) {
  const [permission, setPermission] = useState<null | boolean>(null);

  useEffect(() => {
    getTCFSync(tcf => {
      tcf.subscribe(category, (value: number | string) => {
        setPermission(Boolean(Number(value)));
      });
    });
  }, [category]);

  return permission;
}
