import type { Article, Topic } from "../../client/ContentApi/models";
import type { TargetArticleType } from "../../contexts/TrackingContext";

import { getCanvasAdBehaviour } from "../../components/Article/helpers";
import { getShareImage } from "../../layout/Meta/sharedImage";
import { getSession, updateSession } from "./pulseSession";
import { getSize, mapPathnameToView } from "./events/trackViewEvent";

const subscriptionsRegex = /\/bevakningar[/?]?/;
const articleRegex = /(\/a\/.*)\??/;

const collectArticleTagsTitles = (article: Article) => {
  return article.tags.map(tag => tag.title);
};

const getAccessLevelProps = (isPremium: boolean) => ({
  accessLevelName: isPremium ? "premium" : "free",
  accessLevel: isPremium ? "Paid" : "Free"
});

const getSourceName = (route: string, topic?: Topic, referrer?: string) => {
  switch (route) {
    case "/":
      return "Top";
    case "/latest":
      return "Latest";
    case "/category":
      return "Categories";
    case "/subscriptions":
      return "Subscriptions";
    case "/search":
      return "Search";
    case "/topic":
      if (topic?.type === "story") {
        return "Story";
      }

      return "Topic";
    case "/article":
      if (referrer && subscriptionsRegex.test(referrer)) {
        return "SubscriptionSubject";
      }

      return "Article";
    default:
      return null;
  }
};

const getArticleOpenTime = (articleId: string) => {
  return getSession().articleOpenTimes[articleId];
};

const getArticleImage = (article: Article) => {
  if (article?.main_resource) {
    const image = getShareImage(article.main_resource);

    if (image?.url) {
      const imageUrl = new URL(image.url);
      return `${imageUrl.origin}${imageUrl.pathname}`;
    }
  }

  return null;
};

const getAuthors = (article: Article): string[] => {
  return article.authors.reduce((emails: string[], author) => {
    const email = author?.contacts?.filter(contact => {
      return contact.type === "email";
    });

    if (email) {
      email.forEach(mail => {
        if (mail.value) {
          emails.push(mail.value);
        }
      });
    }

    return emails;
  }, []);
};
export const getTargetUrl = (
  article: Article,
  NEXT_PUBLIC_DOMAIN: string
): string => {
  const maybeCanvasUrl = getCanvasAdBehaviour(article)?.target;

  return maybeCanvasUrl
    ? maybeCanvasUrl
    : `https://${NEXT_PUBLIC_DOMAIN}/a/${article.article_id}`;
};

export const getOrigin = (
  NEXT_PUBLIC_DOMAIN: string,
  route: string,
  referrer: string
) => {
  if (route === "/article" && subscriptionsRegex.test(referrer)) {
    return {
      origin: {
        url: `https://${NEXT_PUBLIC_DOMAIN}/bevakningar`
      }
    };
  } else if (route === "/article" && articleRegex.test(referrer)) {
    const match = referrer.match(articleRegex);

    if (match && match[1]) {
      return {
        origin: {
          url: `https://${NEXT_PUBLIC_DOMAIN}${match[1]}`
        }
      };
    }
  }

  return {};
};

export const updateArticleOpenTime = (articleId: string) => {
  if (articleId) {
    const originalSession = getSession();
    originalSession.lastUpdate = new Date().getTime();
    originalSession.articleOpenTimes[articleId] = new Date().getTime();
    updateSession(originalSession);
  }
};

export const createTrackedViewedAd = (
  PULSE_CLIENT_ID: string,
  NEXT_PUBLIC_DOMAIN: string,
  pathname: string,
  article: Article,
  position: number
) => {
  const articleId = article.article_id;
  const view = mapPathnameToView(pathname);

  const sponsor = article.sponsor;
  const is_sponsored = !!sponsor;
  const id = `sdrn:${PULSE_CLIENT_ID}:article:${article.article_id}`;

  return {
    target: {
      "@id": id,
      "@type": "ArticleReference",
      name: article.title.value,
      url: getTargetUrl(article, NEXT_PUBLIC_DOMAIN)
    },
    object: {
      "@id": id,
      type: "ArticleReference",
      position,
      "spt:custom": {
        article_id: articleId,
        article_title: article.title.value,
        sponsor,
        is_sponsored,
        ...(article.teaser_layout === "Fishstick" && {
          context: "InfoCardPosition"
        }),
        ...(article.meta?.in_cluster && { context: "NativeCluster" }),
        size: getSize(article, view)
      }
    }
  };
};

export const createTrackedArticle = (
  article: Article,
  route: string,
  NEXT_PUBLIC_DOMAIN: string,
  NEXT_PUBLIC_PREMIUM_NEWSROOM: boolean,
  isLoggedIn: boolean,
  user_id: string,
  topic?: Topic,
  referrer?: string
) => {
  const articleId = article.article_id;
  const categoryId = article.category.category_id;

  const categoryTitle = article.sponsor || article.category.title || "";

  const tags = articleId && collectArticleTagsTitles(article);

  const name =
    (articleId && article.title.value) ||
    (categoryId && article.category.title) ||
    "";

  const sponsor = article.sponsor;
  const is_sponsored = !!sponsor;

  return {
    "@id": `sdrn:omnise:article:${article.article_id}`,
    "@type": "Article",
    name: name,
    category: categoryTitle,
    tags: tags,
    url: `https://${NEXT_PUBLIC_DOMAIN}/a/${article.article_id}`,
    published: article.changes.published,
    authors: getAuthors(article),
    imageUrl: getArticleImage(article),
    ...getAccessLevelProps(NEXT_PUBLIC_PREMIUM_NEWSROOM || article.is_premium),
    "spt:custom": {
      is_sponsored,
      sponsor,
      source: getSourceName(route, topic, referrer),
      main_resource: article.main_resource ? article.main_resource.type : "",
      navigation: "Enter",
      vignette_type: article.story_vignette?.title || "",
      article_format: article.article_layout || "",
      size: article.teaser_layout?.toLowerCase()
    }
  };
};

export const countDuration = (articleId: string) => {
  if (articleId) {
    const openTime = getArticleOpenTime(articleId);
    if (openTime) {
      return new Date().getTime() - openTime;
    }
  }
  return 0;
};

export const getArticleTopicObject = ({
  name,
  topicId,
  article,
  pulseId
}: {
  name: string;
  topicId: string;
  article: Article;
  pulseId: string;
}) => {
  return {
    type: "Engagement",
    schema: "http://schema.schibsted.com/events/engagement-event.json/186.json",
    action: "Click",
    object: {
      elementType: "Button",
      name,
      "@type": "UIElement",
      "@id":
        "sdrn:" +
        pulseId +
        ":article:" +
        article.article_id +
        ":element:" +
        topicId
    },
    origin: {
      "@type": "Article",
      name: article.title ? article.title.value : "",
      category: article.category ? article.category.title : "",
      "@id": "sdrn:" + pulseId + ":article:" + article.article_id
    }
  };
};

export const createEvergreenObject = (
  originArticle: Article,
  targetArticle: TargetArticleType,
  NEXT_PUBLIC_PULSE_CLIENT_ID: string
) => {
  return {
    type: "UIElement",
    "@id": `sdrn:${NEXT_PUBLIC_PULSE_CLIENT_ID}:article:element:inarticleevergreenlink:${targetArticle.article_id}`,
    elementType: "InArticleEvergreenLink",
    name: targetArticle.title,
    "spt:custom": {
      origin_article_id: originArticle.article_id,
      target_article_id: targetArticle.article_id,
      target_article_vignette: targetArticle.vignette?.value
    }
  };
};

export const createTargetArticle = (
  article: Pick<Article, "article_id" | "title" | "is_premium"> &
    Partial<Article>,
  url: string
) => ({
  id: article.article_id,
  type: "Article",
  name: article.title.value,
  url,
  category: article?.sponsor ? article.sponsor : article.category?.title,
  ...getAccessLevelProps(article.is_premium)
});
