import type { Topic, Category, Article } from "../../../client";

function getName(
  view: string,
  realPath: string,
  topic?: Topic,
  category?: Category
): string | undefined {
  switch (view) {
    case "category":
      return category?.title;
    case "subscriptions":
      return "SubscriptionSubject";
    case "topic":
      return topic?.title;
    default:
      return view.charAt(0).toUpperCase() + view.slice(1);
  }
}

export function mapPathnameToView(pathname: string): string {
  if (pathname === "/" || pathname === "") {
    return "top";
  }

  if (pathname === "/topic" || pathname === "/topic_w_o_slug") {
    return "topic";
  }

  return pathname.slice(1);
}

export function getType(view: string) {
  if (view === "top" || view === "latest") {
    return "Frontpage";
  }

  return "Listing";
}

export function getUrl(
  view: string,
  NEXT_PUBLIC_DOMAIN: string,
  pathname: string,
  topic?: Topic
): string {
  const baseUrl = `https://${NEXT_PUBLIC_DOMAIN}/`;

  switch (view) {
    case "category":
      return new URL(pathname, baseUrl).toString();
    case "topic":
      return new URL(`t/${topic?.topic_id}`, baseUrl).toString();
    default:
      if (pathname) {
        return new URL(pathname, baseUrl).toString();
      }

      return new URL(baseUrl).toString();
  }
}

export function getSize(article: Article, view: string) {
  if (view === "subscriptions" || view === "article") {
    return article.sponsor ? "sponsoredarticlecarousel" : "articlecarousel";
  }
  return article.teaser_layout?.toLowerCase();
}

export function getId(
  NEXT_PUBLIC_PULSE_CLIENT_ID: string,
  type: string,
  view: string,
  topic?: Topic
) {
  const lowerCaseType = type.toLowerCase();
  const base = `sdrn:${NEXT_PUBLIC_PULSE_CLIENT_ID}:${lowerCaseType}`;

  switch (view) {
    case "subscriptions":
      return `${base}:subscriptionsubject`;
    case "topic":
      if (topic?.type === "story") {
        return `${base}:story`;
      }

      return `${base}:${view}`;
    default:
      return `${base}:${view}`;
  }
}

function getCategory(view: string, topic?: Topic) {
  if (topic) {
    if (view === "topic") {
      if (topic.type === "story") {
        return "Story";
      }

      return "Topic";
    }
  }

  return undefined;
}

export function getObject(
  NEXT_PUBLIC_PULSE_CLIENT_ID: string,
  NEXT_PUBLIC_DOMAIN: string,
  pathname: string,
  realPath: string,
  topic?: Topic,
  category?: Category
) {
  const view = mapPathnameToView(pathname);
  const type = getType(view);
  const name = getName(view, realPath, topic, category);
  const topicType = getCategory(view, topic);

  return {
    "@id": getId(NEXT_PUBLIC_PULSE_CLIENT_ID, type, view, topic),
    "@type": type,
    name,
    url: getUrl(view, NEXT_PUBLIC_DOMAIN, realPath, topic),
    category: topicType
  };
}

export function trackViewEvent(
  pulseSdk: any,
  {
    NEXT_PUBLIC_PULSE_CLIENT_ID,
    NEXT_PUBLIC_DOMAIN,
    pathname,
    asPath,
    topic,
    category
  }: {
    NEXT_PUBLIC_PULSE_CLIENT_ID: string;
    NEXT_PUBLIC_DOMAIN: string;
    pathname: string;
    asPath: string;
    topic?: Topic;
    category?: Category;
    isLoggedIn: boolean;
  }
) {
  pulseSdk?.track("trackerEvent", {
    type: "View",
    schema: "http://schema.schibsted.com/events/tracker-event.json/252.json",
    object: getObject(
      NEXT_PUBLIC_PULSE_CLIENT_ID,
      NEXT_PUBLIC_DOMAIN,
      pathname,
      asPath,
      topic,
      category
    )
  });
}
