import React from "react";
import { useRouter } from "next/router";

import type { AppProps } from "next/app";

import "../assets/less/index.less";
import "@schibsted/data-controller/dist/styles.css";
import { Head } from "../layout/Head";
import { Scripts } from "../layout/HeadScripts/Scripts";

const OmniWeb: React.FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter();

  return (
    <>
      <Head />
      <Scripts />
      <Component key={router.asPath} {...pageProps} />
    </>
  );
};

export default OmniWeb;
