import { ApiClient } from "../../apiClient";
import { PremiumStateError } from "../../PremiumStateError";

export type VerifyPremium = {
  isPremium: boolean;
  isAdFree: boolean;
};

export async function verifyPremium({
  signal,
  exchangeToken
}: {
  signal?: AbortSignal;
  exchangeToken?: string;
}): Promise<VerifyPremium> {
  const settingsClient = new ApiClient("GET", "settings");
  try {
    const response = await settingsClient.send("/verify-premium", {
      mode: "cors",
      credentials: "include",
      signal,
      exchangeToken,
      cache: "no-store"
    });

    return {
      isPremium: response.premium,
      isAdFree: response.ad_free_offer
    };
  } catch (e) {
    if (e instanceof PremiumStateError) {
      throw e;
    }

    console.error(`verify premium response error: ${e}`);
    throw e;
  }
}
