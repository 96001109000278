const passionLayouts = [
  "Passion",
  "PassionSmall",
  "PassionLarge",
  "PassionSponsored"
];

export function checkIfPassion(value: string | undefined) {
  if (value === undefined) {
    return false;
  }

  return passionLayouts.indexOf(value) > -1;
}
