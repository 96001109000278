/**
 * Omni Article Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import type { Inscription } from "./inscription";
import type { Block } from "./block";

export type BlockListOrdered = {
  block_type: "BlockListOrdered" | "list:ordered";
  text: Inscription;
  items: Inscription[];
};
export function isBlockListOrdered(block: Block): block is BlockListOrdered {
  return (
    block.block_type === "BlockListOrdered" ||
    block.block_type === "list:ordered"
  );
}
