export class Error404 extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "NOT_FOUND";
  }
}
export class Error500 extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "INTERNAL_SERVER_ERROR";
  }
}

export interface PropsWithErrorCode {
  errorCode?: number;
}
