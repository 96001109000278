import type { Article, Component } from "../client";

export const isArticle = (
  component: Component | undefined
): component is Article => {
  if (component === undefined) {
    return false;
  }

  return component.type === "Article";
};
